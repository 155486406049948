/* eslint-disable */
// prettier-ignore
import {
    Badge,
    Button,
    Select,
    SelectItem,
    TextInput as Input,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    TableFoot,
    TableFooterCell,
} from '@tremor/react';
import { useVirtualizer } from '@tanstack/react-virtual'

import {
    filterFns,
    SortingState,
    ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues, //depends on getFacetedRowModel
    useReactTable,
} from '@tanstack/react-table';

import { ArrowCircleRightSharp, CheckSharp, EditSharp, FlagSharp, KeyboardArrowDownSharp, KeyboardArrowUpSharp, UnfoldMoreSharp } from '@mui/icons-material';
import { useEffect, useMemo, useRef, useState } from 'react';
// import { CtrlDialogControl } from 'layouts/cysr/comply/components/dialog-control';
import cysrConfig from "layouts/cysr/config";
import { toast } from "sonner"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}) {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <Input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}
const multiSelectFilter = (
    row,
    columnId,
    filterValue,
) => {
    const rowValue = JSON.parse(row.getValue(columnId));
    return rowValue.indexOf(JSON.parse(filterValue)[0]) > -1
};
function processValue(input) {
    let string = ""
    try {
        input = JSON.parse(input)
    } catch (error) {

    }
    if (Array.isArray(input)) {
        input.forEach((element, index) => {
            string += `${index > 0 ? ", " : ""}${element}`;
        });
    } else {
        string = input;
    }
    return string
}
function Filter({ column }) {
    const { filterVariant } = column.columnDef.meta ?? {}
    if (filterVariant === 'none') {
        return null
    }

    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = useMemo(
        () =>
            Array.from(column.getFacetedUniqueValues().keys())
                .sort()
                .slice(0, 5000),
        [column.getFacetedUniqueValues(), filterVariant]
    )
    let frameworksSet = [];
    return filterVariant === 'select' ? (
        <div className="w-full flex items-center gap-2 flex-row max-w-lg">
            <Select className="w-full" id="maturity_level" name="maturity_level" onValueChange={(x) => column.setFilterValue(x)}
                value={columnFilterValue?.toString()}
            >
                <SelectItem key={'all'} value={''}>All</SelectItem>
                {sortedUniqueValues.map(value => {
                    return column.columnDef?.accessorKey === 'frameworks' ? (
                        JSON.parse(value).map(x => {
                            if (frameworksSet.indexOf(x) > -1) {
                                return null
                            } else {
                                frameworksSet.push(x)
                                return <SelectItem value={JSON.stringify([x])} key={JSON.stringify([x])}>{x}</SelectItem>
                            }
                        })
                    ) : (
                        <SelectItem value={value} key={value}>{processValue(value)}</SelectItem>
                    )
                })}
            </Select>
        </div>
    ) : (
        <>
            {/* Autocomplete suggestions from faceted values feature */}
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.map((value) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '')}
                onChange={value => column.setFilterValue(value)}
                placeholder={""}
                className="w-36 border shadow rounded-tremor-default"
                list={column.id + 'list'}
            />
        </>
    )
}
export function CtrlTableControls({ setControlsSorting, language, darkMode, answers, setAnswers, controls, setIsControlOpen, loadControl }) {
    const [sorting, setSorting] = useState([])
    const [columnFilters, setColumnFilters] = useState([])
    const [columnVisibility, setColumnVisibility] = useState({
        // here set default visibility of columns!
        // status: false,
        order: false,
    });
    const data = useMemo(
        () =>
            Object.entries(controls).map((item, i) => {
                const k = item[0];
                const c = item[1];
                const a = answers[k];
                return ({
                    order: c.order,
                    article_order: c.article_order,
                    scfcontrol: c.scfcontrol,
                    frameworks: c.frameworks,
                    code: k,
                    status: a?.answer_status,
                    maturity: a?.answer,
                    description: a?.answer_description,
                    target: a?.target_answer ? a?.target_answer : a?.target_control_answer,
                    action: item,
                })
            }),
        [controls, answers]
    );

    const columns = useMemo(
        () => [
            {
                header: 'Order',
                accessorKey: 'order',
                enableColumnFilter: false,
                enableSorting: true,
                meta: {
                    align: 'text-left',
                    filterVariant: 'none'
                },
                sortingFn: 'alphanumeric',
            },
            {
                header: 'Code',
                accessorKey: 'code',
                enableSorting: true,
                meta: {
                    align: 'text-center',
                },
                sortingFn: 'alphanumeric',
                cell: ({ cell, row }) => {
                    return <button style={{
                        minWidth: "360px",
                    }} className='inline-flex flex-col w-full overflow-hidden mr-auto' onClick={() => {
                        loadControl(row.original.code, language)
                        setIsControlOpen(row.original.code)
                        // setControlOpen(row.original.action)
                    }}><strong className='text-black dark:text-white text-base font-medium'>{row.original.code}</strong><span className='text-left text-ellipsis w-full overflow-hidden'>
                            {row.original?.scfcontrol}
                        </span>
                    </button>
                },
            },
            {
                header: 'Frameworks (in questionnaire)',
                accessorKey: 'frameworks',
                accessorFn: row => JSON.stringify(row.frameworks),
                filterFn: 'multiSelectFilter',
                enableSorting: true,
                meta: {
                    align: 'text-left',
                    filterVariant: 'select'
                },
                sortingFn: (rowA, rowB, columnId) => {
                    const aoA = rowA.original.article_order ? rowA.original.article_order[0] : "Z";
                    const aoB = rowB.original.article_order ? rowB.original.article_order[0] : "Z";

                    if (aoA < aoB) return -1;
                    if (aoA > aoB) return 1;

                    // else use current check

                    const frameworksA = rowA.original.frameworks ? JSON.stringify(rowA.original.frameworks.sort()) : "Z";
                    const frameworksB = rowB.original.frameworks ? JSON.stringify(rowB.original.frameworks.sort()) : "Z";

                    //// Convert to lowercase for case-insensitive comparison
                    //const lowerCaseframeworksA = frameworksA.toLowerCase();
                    //const lowerCaseframeworksB = frameworksB.toLowerCase();

                    if (frameworksA < frameworksB) return -1;
                    if (frameworksA > frameworksB) return 1;
                    return 0;
                },
                cell: ({ cell, row }) => {
                    return (<div style={{
                        minWidth: "280px",
                        maxWidth: "360px",
                    }} className='flex gap-2 w-full overflow-hidden items-center h-10'>
                        {
                            row.original.frameworks?.map((f, bIndex) => <Badge key={bIndex} className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{f}{
                                row.original.article_order && row.original.article_order[0] ? ` (Art. ${row.original.article_order[0]?.split(":")[1]})` : ""
                            }</Badge>)
                        }
                    </div>)
                },
            },
            {
                header: 'Status',
                accessorKey: 'status',
                cell: ({ cell, row }) => <div style={{
                    width: "120px"
                }}>
                    <StatusBadge status={row.original.status} />
                </div>,
                enableSorting: true,
                filterFn: 'equalsString',
                meta: {
                    align: 'text-left',
                    filterVariant: 'select'
                },
                sortingFn: (rowA, rowB, columnId) => {
                    const statusA = rowA.original.status ? rowA.original.status : "Unanswered";
                    const statusB = rowB.original.status ? rowB.original.status : "Unanswered";

                    //// Convert to lowercase for case-insensitive comparison
                    //const lowerCaseStatusA = statusA.toLowerCase();
                    //const lowerCaseStatusB = statusB.toLowerCase();

                    if (statusA < statusB) return -1;
                    if (statusA > statusB) return 1;
                    return 0;
                }
            },
            {
                header: 'Maturity',
                accessorKey: 'maturity',
                enableSorting: true,
                meta: {
                    align: 'text-right',
                    filterVariant: 'select'
                },
                sortingFn: 'alphanumeric'
            },
            /*
            {
                header: 'Description',
                accessorKey: 'description',
                cell: ({ cell, row }) => (<div className='max-w-md'>
                    <p className='whitespace-normal line-clamp-2'>{row.original.description}</p>
                </div>),
                enableSorting: false,
                meta: {
                    align: 'text-left',
                }
            },
            {
                header: 'Target',
                accessorKey: 'target',
                enableSorting: true,
                meta: {
                    align: 'text-left',
                },
                sortingFn: 'alphanumeric'
            },
            */
            {
                header: 'Action',
                accessorKey: 'action',
                cell: ({ cell, row }) => {
                    return <Button style={{
                        width: "40px"
                    }} className="ml-auto block border-0" onClick={() => {
                        loadControl(row.original.code, language)
                        setIsControlOpen(row.original.code)
                        // setControlOpen(row.original.action)
                    }}><ArrowCircleRightSharp className='!h-6 !w-6 text-turquoise-400' /></Button>
                },
                enableSorting: false,
                meta: {
                    align: 'text-right',
                    filterVariant: 'none'
                },
            },
        ],
        [controls, answers, language]
    );

    // https://blocks.tremor.so/blocks/table-actions
    const table = useReactTable({
        /*
        {
            workspace: 'sales_by_day_api',
            owner: 'John Doe',
            status: 'live',
            costs: '$3,509.00',
            region: 'US-West 1',
            capacity: '99%',
            lastEdited: '23/09/2023 13:00',
        }
        */
        data: data,
        /*
        Code
        Status
        Maturity
        Description
        Target
        Action
        */
        columns: columns,
        filterFns: {
            'multiSelectFilter': multiSelectFilter,            //filter function added
        },
        initialState: {
            sorting: [
                {
                    id: 'order',
                    desc: false, // sort in descending order by default
                },
            ],
        },
        onSortingChange: (s) => {
            setSorting(s)
            setTimeout(() => {
                // console.log(table.getState().sorting) // access the sorting state from the table instance
                // console.warn(table.getSortedRowModel())
                setControlsSorting(table.getSortedRowModel()?.flatRows?.filter(r => ((Object.values(r.columnFilters).some(x => x[0] === false)) === false)).map(z => z?.original?.code))
            }, 300);
        },
        onColumnFiltersChange: (f) => {
            setColumnFilters(f)
            setTimeout(() => {
                // console.error(table.getSortedRowModel())
                setControlsSorting(table.getSortedRowModel()?.flatRows?.filter(r => ((Object.values(r.columnFilters).some(x => x[0] === false)) === false)).map(z => z?.original?.code))
            }, 300);
        },
        getCoreRowModel: getCoreRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),

        getFacetedRowModel: getFacetedRowModel(), //if you need a list of values for a column (other faceted row models depend on this one)
        getFacetedUniqueValues: getFacetedUniqueValues(), //if you need a list of unique values

        state: {
            sorting, columnFilters,
            columnVisibility,
        }
    });

    const { rows } = table.getRowModel()

    const parentRef = useRef(null)

    const virtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 73,
        overscan: 8,
    })

    return (

        <div
            className='mt-8'
            style={{
                paddingTop: 87,
                position: 'relative',
            }}>
            <div
                ref={parentRef}
                style={{
                    height: `60vh`,
                    minHeight: `720px`,
                    overflow: 'auto',
                }}
            >
                <div
                    style={{
                        height: `${virtualizer.getTotalSize()}px`,
                        width: '100%',
                    }}
                >
                    <table className='tremor-Table-table w-full text-tremor-default text-tremor-content dark:text-dark-tremor-content'>
                        <TableHead className='absolute top-0 w-full'>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow
                                    key={headerGroup.id}
                                    className="border-b border-tremor-border dark:border-dark-tremor-border w-full flex items-end gap-2"
                                >
                                    {headerGroup.headers.map((header) => header?.column?.columnDef?.accessorKey === "action" ? null : (
                                        <div style={
                                            header?.column?.columnDef?.accessorKey === "status" ?
                                                { maxWidth: 150 } :
                                                header?.column?.columnDef?.accessorKey === "maturity" ?
                                                    { maxWidth: 296 } :
                                                    {

                                                    }
                                        } className={`inline-flex flex-col ${header?.column?.columnDef?.accessorKey === "order" ? "w-auto" : "w-full"}`}>
                                            {header.column.getCanFilter() ? (
                                                <div>
                                                    <Filter column={header.column} />
                                                </div>
                                            ) : null}
                                            <TableHeaderCell
                                                key={header.id}
                                                onClick={header.column.getToggleSortingHandler()}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        header.column.getToggleSortingHandler()(event);
                                                    }
                                                }}
                                                className={classNames(
                                                    header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : '',
                                                    'px-0.5 py-1.5',
                                                )}
                                                style={{
                                                    verticalAlign: 'top'
                                                }}
                                                tabIndex={header.column.getCanSort() ? 0 : -1}
                                                aria-sort={header.column.getIsSorted()}
                                            >
                                                <div className='flex flex-col'>
                                                    <div
                                                        className={classNames(
                                                            header.column.columnDef.enableSorting === true
                                                                ? 'flex items-center justify-between gap-2 hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted'
                                                                : header.column.columnDef.meta.align,
                                                            ' rounded-tremor-default px-3 py-1.5',
                                                        )}
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext(),
                                                        )}
                                                        {header.column.columnDef.meta.filterVariant && header.column.columnDef.meta.filterVariant === 'none'
                                                            ? null
                                                            : header.column.columnDef?.accessorKey === "frameworks"
                                                                ? null : ` (${header.column.getFacetedUniqueValues().size})`
                                                        }
                                                        {header.column.getCanSort() ? (
                                                            header.column.getIsSorted() === 'asc' ? (
                                                                <KeyboardArrowUpSharp
                                                                    className="h-6 w-6 text-tremor-content-strong dark:text-dark-tremor-content-strong"
                                                                    aria-hidden={true}
                                                                />
                                                            ) : header.column.getIsSorted() === 'desc' ? (
                                                                <KeyboardArrowDownSharp
                                                                    className="h-6 w-6 text-tremor-content-strong dark:text-dark-tremor-content-strong"
                                                                    aria-hidden={true}
                                                                />
                                                            ) : (
                                                                <UnfoldMoreSharp
                                                                    className="h-6 w-6 text-tremor-content dark:text-dark-tremor-content"
                                                                    aria-hidden={true}
                                                                />
                                                            )
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </TableHeaderCell>
                                        </div>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <tbody className='tremor-TableBody-root align-top'>{table.getRowModel().rows?.length ? (
                            // table.getRowModel().rows.map((row) => (
                            virtualizer.getVirtualItems().map((virtualRow, index) => {
                                const row = rows[virtualRow.index]
                                return (
                                    <TableRow key={row.id}
                                        className='relative'
                                        style={{
                                            height: `${virtualRow.size}px`,
                                            transform: `translateY(${virtualRow.start - index * virtualRow.size
                                                }px)`,
                                        }}>
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell
                                                key={cell.id}
                                                className={classNames(cell.column.columnDef.meta.align)}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                )
                            }
                            )
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="absolute opacity-25 inset-x-0 bottom-0 -mb-1 h-14 rounded-b-lg bg-gradient-to-t from-tremor-background via-tremor-background to-transparent dark:from-gray-950 dark:via-gray-950 dark:to-transparent" />
            <p className="text-zinc-500 dark:text-zinc-300 text-sm pt-4 px-4 pb-2">
                Viewing {table.getSortedRowModel()?.flatRows?.length} of {Object.keys(controls)?.length} controls
            </p>
        </div>
    );

    /*
        return (
            <><Table className="mt-5">
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Code</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell>Maturity</TableHeaderCell>
                        <TableHeaderCell>Description</TableHeaderCell>
                        <TableHeaderCell>Target</TableHeaderCell>
                        <TableHeaderCell>Action</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(controls).map((item, i) => {
                        const k = item[0];
                        const c = item[1];
                        const a = answers[k];
                        return (
                            <TableRow key={k}>
                                <TableCell>{k}</TableCell>
                                <TableCell>
                                    {a && a.answer_status ?
                                        <Badge color="emerald" icon={FlagSharp}
                                        >
                                            {a.answer_status}
                                        </Badge>
                                        :
                                        <Badge color="red" icon={FlagSharp}
                                        >
                                            Unanswered
                                        </Badge>
                                    }
                                </TableCell>
                                <TableCell>
                                    {a.answer ? a.answer : null}
                                </TableCell>
                                <TableCell>
                                    <div className='max-w-md'>
                                        <p className='whitespace-normal line-clamp-2'>{a.answer_description}</p>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {a.target_answer ? a.target_answer : a.target_control_answer}
                                </TableCell>
                                <TableCell>
                                    <CtrlDialogControl controlInfo={controlInfo} loadControl={loadControl} prevK={Object.keys(controls)[i - 1]} nextK={Object.keys(controls)[i + 1]} isControlOpen={isControlOpen} setIsControlOpen={setIsControlOpen} darkMode={darkMode} item={item} answers={a} // controls={controls} setControls={setControls} 
                                        controlsAnswers={answers} setControlsAnswers={setAnswers} statuses={statuses} />
                                </TableCell>
                            </TableRow>
                        )
                    }
                    )}
                </TableBody>
                <TableFoot>
                    <TableFooterCell>
                        {
                            // End of the list.
                        }
                    </TableFooterCell>
                </TableFoot>
            </Table>
            </>
        )
        */
}

export function StatusBadge({ status, defaultString = "UNANSWERED" }) {
    return (status && status.toUpperCase() != defaultString.toUpperCase()) ?
        <Badge color={status === "DRAFT" ? "orange" : "emerald"} icon={status === "DRAFT" ? EditSharp : CheckSharp}
        >
            {status}
        </Badge>
        :
        <Badge color="red" icon={FlagSharp}
        >
            {defaultString}
        </Badge>;
}
/* eslint-disable */
// prettier-ignore