/* eslint-disable */
// prettier-ignore
import {
  Badge,
  Button, Card, Dialog, DialogPanel, Divider,
  Select,
  SelectItem,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TextInput
} from '@tremor/react';
import { useEffect, useMemo, useState } from 'react';
import {
  CloseButton,
  Popover, PopoverPanel,
  Field, PopoverButton, Radio, RadioGroup
} from '@headlessui/react'

import cysrConfig from "layouts/cysr/config";
import { CtrlTextareaObjectiveNote } from "layouts/cysr/comply/components/textarea-objective-note";
import { toast } from "sonner"
import { CheckBoxSharp, CircleSharp, EditSharp, GraphicEqSharp, KeyboardArrowDownSharp, KeyboardArrowUpSharp, NoteAddSharp, RadioButtonCheckedSharp, RadioButtonUncheckedSharp, WarningAmberSharp } from '@mui/icons-material';
import { CtrlInfoDialog } from './info-dialog';
import CtrlTrackerControl from './tracker-control';
import { CtrlUploadControlEvidence } from './upload-control-evidence';
import { CtrlEvidenceArtifactsList } from './control-evidence-artifacts-list';
import { CtrlWizardControlAsset } from './wizard-control-asset';
import { PopoverControl } from './popover-control';
import { StatusBadge } from './table-controls';
import { LawPanel } from './law-panel';
import { specificColor } from './comply-sankey-charts';

export function getAccountUUID(){
  // invited company url like:
  // https://dashboard-dev.mu-rating-backend-01.sq.biz/invite-comply-questionnaire?company=b82a5b9d-cd3b-4431-8f05-bc61db30ef99#685f14ef-e51b-4cd8-8469-642fa5c3e9d9
  const queryString = window.location.search; // Get the query string
  const urlParams = new URLSearchParams(queryString); // Create a URLSearchParams object
  let accountUUID = urlParams.get('company'); // Get the value of 'company'

  if (accountUUID && accountUUID.length > 0) {
    // ok got it from ?company ~ invited url
  } else {
    // not found in ?company so is standard
    accountUUID = window.location.pathname.substring(
      window.location.pathname.indexOf("/company/") + "/company/".length,
      window.location.pathname.lastIndexOf("/")
    );
  }
  return accountUUID
}


function postAsset(dataObject) {
  if (dataObject && dataObject.asset_name && dataObject.asset_description) {
    const accountUUID = getAccountUUID();
    // POST
    // const postAssetUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/asset`;
    const postAssetUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/asset`;
    fetch(postAssetUrl, {
      body: JSON.stringify(dataObject),
      headers: {
        ...(MuscopeInviteAuth ? { "Muscope-Invite-Auth": MuscopeInviteAuth } : { Authorization: `Bearer ${localStorage.getItem("cysr_access")}` }),
        "content-type": "application/json",
      },
      method: "POST"
    })
      .then((response) => response.json())
      .then(async (assetAnswerResponse) => {
        /*
        let newA = { ...a }
        if (newA.assets && newA.assets[oKey]) {
          newA.assets[oKey].asset_answer_id = givenAnswerID;
        } else {
          const newItem = {
            [oKey]: {
              asset_answer_id: givenAnswerID
            }
          };
          newA.assets = { ...newA.assets, ...newItem };
        }
        let newControlsAnswers = { ...controlsAnswers }
        newControlsAnswers[controlID] = newA
        setA(newA);
        */
        if (assetAnswerResponse && assetAnswerResponse.Msg) {
          toast.success(assetAnswerResponse.Msg);
        }

      })
      .catch((err) => {
        console.error(1, err)
      });
  } else {
    toast.error("Fill all fields to add the asset")
  }
}

function updateMaturityLevel(MuscopeInviteAuth, controlID, valueOfMaturityLevel, valueOfTargetMaturityLevel, a, setA, c, controlsAnswers, setControlsAnswers, method) {
  let info = "";
  if (valueOfMaturityLevel && valueOfTargetMaturityLevel) {
    // ok
    info = false
  } else if (valueOfMaturityLevel) {
    valueOfTargetMaturityLevel = valueOfMaturityLevel;
    info = "Target maturity value has been also saved with the same level until you provide a value"
  } else if (valueOfTargetMaturityLevel) {
    toast.warning("Please provide the value of Maturity level to save both maturity levels")
    return false
  }
  if (controlID) {
    const accountUUID = getAccountUUID();
    // POST/PATCH
    const postControlUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/control/${controlID}/`;
    let b = {
      answer_id: valueOfMaturityLevel,
      target_answer_id: valueOfTargetMaturityLevel
    }
    if (a?.answer_status
      && a?.answer_status.toUpperCase() === 'ANSWERED'
      || a?.answer_status.toUpperCase() === 'DRAFT'
    ) {
      //keep like that
    } else {
      b.answer_status = 'DRAFT'
    }
    fetch(postControlUrl, {
      body: JSON.stringify(b),
      headers: {
        ...(MuscopeInviteAuth ? { "Muscope-Invite-Auth": MuscopeInviteAuth } : { Authorization: `Bearer ${localStorage.getItem("cysr_access")}` }),
        "content-type": "application/json",
      },
      method: method ? method : a?.answer_id ? "PATCH" : "POST"
    })
      .then((response) => response.json())
      .then(async (controlAnswerResponse) => {
        if (controlAnswerResponse && controlAnswerResponse.Msg) {
          // setA here s

          const choAns = Object.entries(c.choices).find((choice) => {
            const choice_id = choice[0]
            return choice_id === valueOfMaturityLevel
          }) ? Object.entries(c.choices).find((choice) => {
            const choice_id = choice[0]
            return choice_id === valueOfMaturityLevel
          })[1] : null;
          const choTarAns = Object.entries(c.choices).find((choice) => {
            const choice_id = choice[0]
            return choice_id === valueOfTargetMaturityLevel
          }) ? Object.entries(c.choices).find((choice) => {
            const choice_id = choice[0]
            return choice_id === valueOfTargetMaturityLevel
          }) : null;
          /*
           "choice": "L0 Not Performed",
              "description": "There is no evidence of a capability to utilize Multi-Fact or Authentication (MFA) to authenticate network access for privileged accounts. ",
              "short_description": " Not Performed",
              "level": "L0"
          */
          const objA = {
            answer: choAns.choice,
            answer_description: choAns.description,
            answer_id: valueOfMaturityLevel,
            answer_status: b?.answer_status ? b?.answer_status : a?.answer_status,
            objectives: a?.objectives,
            // target_control_answer?
            target_answer: choTarAns.choice,
            target_answer_description: choTarAns.description,
            target_answer_id: valueOfTargetMaturityLevel
          }
          let newControlsAnswers = { ...controlsAnswers }
          newControlsAnswers[controlID] = objA
          setControlsAnswers(newControlsAnswers)
          setA(objA)
          // setA here e
          toast.success(controlAnswerResponse.Msg)
          if (info && info.length > 0) {
            toast.info(info)
          }
        } else if (controlAnswerResponse && controlAnswerResponse.Error) {
          updateMaturityLevel(MuscopeInviteAuth, controlID, valueOfMaturityLevel, valueOfTargetMaturityLevel, a, setA, c, controlsAnswers, setControlsAnswers, "POST")
        }
      })
      .catch((err) => {
        console.error(1, err)
      });
  } else {
    toast.error("Error getting the answer contact the support.")
  }
}

export function CtrlDialogControl({ MuscopeInviteAuth, darkMode, loadControl, companyEvidences, setCompanyEvidences, controlInfo, prevK, nextK, isControlOpen, setIsControlOpen, item, answers, controlsAnswers, setControlsAnswers, statuses, controls, language }) {

  const [readOnly, setReadOnly] = useState(false);

  const [a, setA] = useState(null);
  const [k, setK] = useState(null)
  const [c, setC] = useState(null)
  const [lawPanelOpen, setLawPanelOpen] = useState(false)
  const showEvidences = MuscopeInviteAuth ? false : (controlInfo && controlInfo.control_evidencerequest && controlInfo.control_evidencerequest.length > 0) ? true : false;

  const descriptions = {
    information: {
      label: "Info",
      text: null
    },
    levels: {
      label: "Maturity Levels",
      text: null
    },
    objectives: {
      label: "Objectives",
      text: null
    },
    evidences: {
      label: "Evidence/Artifacts",
      text: null
    },
    information: {
      label: "Information",
      text: null
    },
    risk: {
      label: "Risk",
      text: null
    },
    threat: {
      label: "Threat",
      text: null
    },
  }
  const [valueOfMaturityLevel, setValueOfMaturityLevel] = useState(null);
  const [valueOfTargetMaturityLevel, setValueOfTargetMaturityLevel] = useState(null);
  useMemo(() => {
    setK(item[0])
    let newC = item[1];
    // overwrite objectives with the ones in control info
    let overwrittenObjectives = {};
    controlInfo?.control_objective?.forEach(co => {
      let co_choices = {};
      (co.objective_objectivechoice).forEach(cco => {
        co_choices[cco.objectivechoice_id] = {
          choice: cco.objectivechoice_choice
        }
      });

      overwrittenObjectives[co.objective_id] = {
        objective: co.objective_objective,
        choices: co_choices
      }

    })

    // newC['objectives'] = item[1]['objectives']
    newC['objectives'] = overwrittenObjectives;
    setC(newC)

    let newAnswers = answers;
    let overwrittenAnsweredObjectives = {};
    controlInfo?.control_objectives_answer?.forEach(coa => {
      overwrittenAnsweredObjectives[coa.objective_id] = {
        objective_answer: coa.choice,
        objective_answer_id: coa.choice_id,
        objective_note: coa.note?.note_text,
        objective_compensation: coa.compensating_control
      }
    })
    newAnswers['objectives'] = overwrittenAnsweredObjectives;
    //added to fix the bars not updating properly s
    let newControlsAnswers = { ...controlsAnswers }
    // ??? newControlsAnswers['objectives'] = overwrittenAnsweredObjectives
    setControlsAnswers(newControlsAnswers)
    //added to fix the bars not updating properly e


    setA(newAnswers)
    // get the DB value of maturity level for control s
    setValueOfMaturityLevel(Object.keys(item[1].choices).find((choice_id) => {
      return choice_id === answers?.answer_id
    }))
    // target_answer
    setValueOfTargetMaturityLevel(Object.keys(item[1].choices).find((choice_id) => {
      return choice_id === answers?.target_answer_id
    }))
  },
    // [answers, item, controlInfo]
    [controlInfo]
  )
  //return a && c ? (
  return c ? (
    <>
      {
        /*
      }
        <Button className="mx-auto block border-0" onClick={() => {
          loadControl(k)
          setIsControlOpen(k)
        }}><ArrowCircleRightSharp className='!h-6 !w-6 text-turquoise-400' /></Button>
        */
      }
      <Dialog className={`z-[999999] transition-none ${darkMode ? "dark" : "light"}`} open={isControlOpen === k} onClose={() => setIsControlOpen(false)} static={true} unmount={true}>
        <DialogPanel className='max-w-screen-2xl bg-white/90 dark:bg-[#121619]/80 backdrop-blur-lg ease-out duration-500'>

          <div className="relative">
            <main
              style={{
                boxShadow: `0 8px 10px ${darkMode ? "rgb(18 22 25 / 0.8)" : "rgb(18 22 25 / 0.2)"}`
              }}
              className="relative z-10 flex flex-col justify-between gap-0 rounded-tremor-default bg-tremor-background px-6 py-4 dark:bg-dark-tremor-background ring-tremor-ring dark:ring-dark-tremor-ring ring-1"
            >
              {/* 
              // 20241120
              <button className={
                (readOnly ? "text-white bg-red-500" : "bg-transparent") +
                ' py-1 rounded-tremor-default absolute left-0 right-0 text-xs block'
              }
                style={{
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  top: -24
                }} type='button' onClick={() => setReadOnly(!readOnly)}>
                {readOnly ? <span>Read only mode active</span> : "Edit mode"}
              </button> */}
              <div className="flex items-start justify-start gap-x-6 gap-y-2">
                <div className='flex flex-col gap-1 w-full'>
                  <div className='flex gap-2 items-center'>
                    <button type='button' onClick={() => setIsControlOpen(false)} className='flex gap-1 items-center'>
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355L3.70711 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H3.70711L6.85355 11.1464C7.04882 11.3417 7.04882 11.6583 6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.14645 7.85355C1.95118 7.65829 1.95118 7.34171 2.14645 7.14645L6.14645 3.14645C6.34171 2.95118 6.65829 2.95118 6.85355 3.14645Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                    </button>
                    <h3 className="text-lg font-medium text-base text-tremor-content-strong dark:text-dark-tremor-content-strong">{k}</h3>
                    {controlInfo.control_domain
                      ? <Badge className='bg-cyan-200 dark:bg-cyan-900 text-cyan-800 dark:text-white rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{controlInfo.control_domain}</Badge>
                      : null
                    }
                    <Badge style={{
                      background: specificColor(controlInfo?.control_functiongroup, "#aaa")
                    }} className='bg-turquoise-200 dark:bg-turquoise-800 text-black rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{controlInfo.control_functiongroup}</Badge>
                    <div className="ml-auto flex flex-col gap-1 justify-end">
                      <div className="flex gap-2 justify-end">
                        <Button disabled={!prevK} className="h-8 py-1 text-white bg-turquoise-600" onClick={() => {
                          if (!prevK) {
                            toast.info("No more controls before this")
                          } else {
                            loadControl(prevK)
                          }
                          setIsControlOpen(prevK)
                        }}>
                          Previous
                        </Button>
                        <Button disabled={!nextK} className="h-8 py-1 text-white bg-turquoise-600" onClick={() => {
                          if (!nextK) {
                            toast.info("No more controls after this")
                          } else {
                            loadControl(nextK)
                          }
                          setIsControlOpen(nextK)
                        }}>
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                  <h2 style={{
                    maxHeight: "200px",
                    overflowY: "auto"
                  }} className="relative mb-0 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong whitespace-pre-line">
                    {controlInfo.control_question}
                  </h2>
                  <Divider className='my-2' />
                  <div className='flex gap-2 z-[999999] relative'>
                    <PopoverControl a={a} darkMode={darkMode} ghost={true} className="z-[999999] relative inline-flex items-center" label={
                      null
                      // `Manage ${k}`
                    }>
                      <CtrlSelectMaturityLevel readOnly={readOnly} asRadio={true} darkMode={darkMode} a={a} c={c.choices} aID={a?.target_answer_id} callback={(y) => {
                        setValueOfTargetMaturityLevel(y)
                        updateMaturityLevel(MuscopeInviteAuth, k, valueOfMaturityLevel, y, a, setA, c, controlsAnswers, setControlsAnswers)
                      }} label={"Target Maturity level"} type="target" />
                    </PopoverControl>
                    <CtrlSelectMaturityLevel readOnly={readOnly} darkMode={darkMode} a={a} c={c.choices} aID={a?.answer_id} callback={(x) => {
                      setValueOfMaturityLevel(x)
                      updateMaturityLevel(MuscopeInviteAuth, k, x, valueOfTargetMaturityLevel, a, setA, c, controlsAnswers, setControlsAnswers)
                    }} label={null} type="answer" />
                    <PopoverControl darkMode={darkMode} className="z-[999999] relative" label={a?.answer_status ? a?.answer_status?.toUpperCase() : "UNANSWERED"} statuses={statuses} status={a?.answer_status ? a?.answer_status?.toUpperCase() : "UNANSWERED"} setStatus={(x) => {
                      if (readOnly) { toast.warning("In read only mode") } else {

                        if (valueOfMaturityLevel
                          // && valueOfTargetMaturityLevel
                        ) {
                          const accountUUID = getAccountUUID();
                          // PATCH
                          const patchControlUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/control/${k}/`;
                          fetch(patchControlUrl, {
                            body: JSON.stringify({
                              answer_status: x,
                              // answer_id: valueOfMaturityLevel,
                              // target_answer_id: valueOfTargetMaturityLevel
                            }),
                            headers: {
                              ...(MuscopeInviteAuth ? { "Muscope-Invite-Auth": MuscopeInviteAuth } : { Authorization: `Bearer ${localStorage.getItem("cysr_access")}` }),
                              "content-type": "application/json",
                            },
                            method: "PATCH"
                          })
                            .then((response) => response.json())
                            .then(async (controlAnswerResponse) => {
                              if (controlAnswerResponse && controlAnswerResponse.Msg) {
                                // setA here s  
                                const choAns = Object.entries(c.choices).find((choice) => {
                                  const choice_id = choice[0]
                                  return choice_id === valueOfMaturityLevel
                                }) ? Object.entries(c.choices).find((choice) => {
                                  const choice_id = choice[0]
                                  return choice_id === valueOfMaturityLevel
                                })[1] : null;
                                const choTarAns = Object.entries(c.choices).find((choice) => {
                                  const choice_id = choice[0]
                                  return choice_id === valueOfTargetMaturityLevel
                                }) ? Object.entries(c.choices).find((choice) => {
                                  const choice_id = choice[0]
                                  return choice_id === valueOfTargetMaturityLevel
                                })[1] : null;
                                const objA = {
                                  answer: choAns?.choice,
                                  answer_description: choAns?.description,
                                  answer_id: valueOfMaturityLevel,
                                  answer_status: x,
                                  objectives: a?.objectives,
                                  // target_control_answer?
                                  target_answer: choTarAns?.choice,
                                  target_answer_description: choTarAns?.description,
                                  target_answer_id: valueOfTargetMaturityLevel
                                }
                                let newControlsAnswers = { ...controlsAnswers }
                                newControlsAnswers[k] = objA
                                setControlsAnswers(newControlsAnswers)
                                setA(objA)
                                // setA here e
                                toast.success(controlAnswerResponse.Msg)
                              } else if (controlAnswerResponse && controlAnswerResponse.Error) {
                                toast.error(
                                  controlAnswerResponse.Error
                                )
                                if (controlAnswerResponse.Error === "No response was found for the specified control") {

                                }
                              }

                            })
                            .catch((err) => {
                              console.error(1, err)
                            });
                        } else {
                          toast.warning("Set the maturity level of the control to set the status")
                        }
                      }
                    }} />
                  </div>
                </div>
                {
                  /*
                }
                <div className="ml-auto max-w-xl flex justify-end gap-2">
                  <div className='ml-2 flex flex-col gap-2'>
                    <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content text-right">Status</p>
                    <div
                      align="end"
                      className="border-tremor-border dark:border-dark-tremor-border"
                    >
                      <Select value={a.answer_status?.toUpperCase()} onValueChange={(x) => {
                        let info = "";
                        if (valueOfMaturityLevel && valueOfTargetMaturityLevel) {
                          // ok
                          info = false
                        } else if (valueOfMaturityLevel) {
                          valueOfTargetMaturityLevel = valueOfMaturityLevel;
                          info = "Target maturity value has been also saved with the same level until you provide a value"
                        } else if (valueOfTargetMaturityLevel) {
                          toast.warning("Please provide the value of Maturity level to save both maturity levels")
                          return false
                        }
                        if (valueOfMaturityLevel && valueOfTargetMaturityLevel) {
                          const accountUUID = window.location.pathname.substring(
                            window.location.pathname.indexOf("/company/") + "/company/".length,
                            window.location.pathname.lastIndexOf("/")
                          );
                          // PATCH
                          const patchControlUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/control/${k}/`;
                          fetch(patchControlUrl, {
                            body: JSON.stringify({
                              answer_status: x,
                              // answer_id: valueOfMaturityLevel,
                              // target_answer_id: valueOfTargetMaturityLevel
                            }),
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                              "content-type": "application/json",
                            },
                            method: "PATCH"
                          })
                            .then((response) => response.json())
                            .then(async (controlAnswerResponse) => {
                              if (controlAnswerResponse && controlAnswerResponse.Msg) {
                                // setA here s  
                                const choAns = Object.entries(c.choices).find((choice) => {
                                  const choice_id = choice[0]
                                  return choice_id === valueOfMaturityLevel
                                })[1]
                                const choTarAns = Object.entries(c.choices).find((choice) => {
                                  const choice_id = choice[0]
                                  return choice_id === valueOfTargetMaturityLevel
                                })[1]
                                const objA = {
                                  answer: choAns.choice,
                                  answer_description: choAns.description,
                                  answer_id: valueOfMaturityLevel,
                                  answer_status: x,
                                  objectives: a.objectives,
                                  // target_control_answer?
                                  target_answer: choTarAns.choice,
                                  target_answer_description: choTarAns.description,
                                  target_answer_id: valueOfTargetMaturityLevel
                                }
                                let newControlsAnswers = { ...controlsAnswers }
                                newControlsAnswers[k] = objA
                                setControlsAnswers(newControlsAnswers)
                                setA(objA)
                                // setA here e
                                toast.success(controlAnswerResponse.Msg)
                              }
                              if (info && info.length > 0) {
                                toast.info(info)
                              }
  
                            })
                            .catch((err) => {
                              console.error(1, err)
                            });
                        } else {
                          toast.warning("Set the maturity level of the control to set the status")
                        }
                      }} className="border-tremor-border dark:border-dark-tremor-border sm:w-48" placeholder="Select a status">
                        {statuses.map((item) => (
                          <SelectItem
                            key={item.value}
                            value={item.value}
                            disabled={item.disabled}
                          >
  
                            <div className="flex w-full items-center gap-x-2">
                              // <item.icon
                              // className={classNames(
                              //   item.disabled
                              //   ? 'text-tremor-content-subtle/50'
                              //   : 'text-tremor-content',
                              //   'size-4 shrink-0',
                              // )}
                              // aria-hidden={true}
                              // />
                              {item.label}
                            </div>
  
                          </SelectItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                {
                  */
                }
              </div>
              <div className="flex items-end justify-end gap-6 mt-2">
                <CtrlTrackerControl answers={a?.objectives ? JSON.stringify(a?.objectives) : "[]"} c={c.objectives} label={"Objectives answered"} percentageLabel="Objectives" language={language} />
              </div>
            </main>
            <section
              style={{
                minHeight: "320px",
                maxHeight: "calc(100vh - 360px)"
              }}
              className='flex w-full gap-4 overflow-auto px-6'
            >
              {
                false && <>
                  <Divider>Maturity</Divider>

                  <div className='mt-6 flex gap-4'>
                    <Card className="sm:mx-auto sm:max-w-lg">
                      <h3 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                        Maturity activity
                      </h3>
                      {
                        /*
                          <p className="text-tremor-metric font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                            0
                          </p>
                        */
                      }
                      <div className="mt-4 flex h-44 items-center justify-center rounded-tremor-small border border-dashed border-tremor-border p-4 dark:border-dark-tremor-border">
                        <div className="text-center">
                          <GraphicEqSharp
                            className="mx-auto h-7 w-7 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
                            aria-hidden={true}
                          />
                          <p className="mt-2 text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                            No activity measured yet
                          </p>
                          <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                            Available soon
                          </p>
                        </div>
                      </div>
                    </Card>


                  </div>
                </>}
              <TabGroup defaultIndex={0} className="mt-6">
                <TabList>
                  <Tab>Objectives</Tab>
                  <Tab>Maturity Levels</Tab>
                  {showEvidences
                    ?
                    <Tab>Evidences</Tab>
                    : null
                  }
                  <Tab>Information</Tab>
                  <Tab>Risk</Tab>
                  <Tab>Threat</Tab>
                  {
                    // <Tab>Remediation plan</Tab>
                    // <Tab>Notes</Tab>
                  }
                </TabList>
                {showEvidences
                  ?

                  <TabPanels>
                    <TabPanel>
                      <TabPanelIntro descriptions={descriptions} value={"objective"} />
                      {(a && a.answer_id) ?
                        <CtrlObjectives readOnly={readOnly} controlID={k} o={c.objectives} a={a} setA={setA} controlsAnswers={controlsAnswers} setControlsAnswers={setControlsAnswers} controls={Object.keys(controls).filter(z => controlsAnswers[z]?.answer_id)} allControlsData={controls} />
                        : <BannerMaturityLevelMissing />
                      }
                    </TabPanel>
                    <TabPanel>
                      <TabPanelIntro descriptions={descriptions} value={"levels"} />
                      <>
                        <div className='mt-6 flex flex-col gap-2 max-w-3xl'>
                          <h2 className='text-sm'>
                            Maturity Levels
                          </h2>
                          {c?.choices ? Object.values(c.choices).map(mlc => <>
                            <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">{mlc?.choice}</h3>
                            <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content whitespace-pre-line'>
                              {mlc?.description}
                            </p>
                          </>) : "No maturity levels found for this control."}
                        </div>
                      </>
                    </TabPanel>
                    {showEvidences &&
                      <TabPanel>
                        <TabPanelIntro descriptions={descriptions} value={"evidences"} />
                        <p className="mt-6 leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">List of {controlInfo?.control_evidencerequest?.length} evidences for the control.</p>
                        <div className="mt-6 flex flex-col gap-12">
                          {
                            controlInfo.control_evidencerequest ?
                              controlInfo.control_evidencerequest.map((x) =>
                                <div className="flex flex-col gap-6 xl:flex-row items-start">
                                  <CtrlEvidenceArtifactsList controlID={k} data={x} artifactsData={companyEvidences.filter(z => z.artifact_control?.indexOf(k) > -1)} />
                                  <CtrlUploadControlEvidence controlID={k} loadControl={loadControl} data={x} setCompanyEvidences={setCompanyEvidences} artifactsData={companyEvidences.find(z => z.artifact_control?.indexOf(k) > -1)} />
                                </div>
                              )
                              : null
                          }
                        </div>
                      </TabPanel>
                    }
                    <TabPanel>
                      <TabPanelIntro descriptions={descriptions} value={"information"} />
                      {(a && a.answer_id) ?
                        <>
                          <div className='mt-6 flex flex-col gap-2 max-w-3xl'>
                            <h2 className='text-sm'>
                              Maturity Level
                            </h2>
                            <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">{a?.answer}</h3>
                            <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content whitespace-pre-line'>
                              {a?.answer_description}
                            </p>
                          </div>
                          {a?.target_answer_id && a?.target_answer_description ?
                            <>
                              <Divider />
                              <div className='mt-6 flex flex-col gap-2 max-w-3xl'>
                                <h2 className='text-sm'>
                                  Target Maturity Level
                                </h2>
                                <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">{a?.target_answer ? a?.target_answer : a?.target_control_answer}</h3>
                                <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content whitespace-pre-line'>
                                  {a?.target_answer_description}
                                </p>
                              </div>
                            </> : null}
                        </>
                        : <BannerMaturityLevelMissing title="Set a Maturity Level for the Control to view Information" text={<>
                          To view the information associated with a particular control, you need to set the maturity level for that control.
                          <br />This allows you to see the information that correspond to the specified maturity level.
                        </>} />
                      }
                    </TabPanel>
                    <TabPanel>
                      <TabPanelIntro descriptions={descriptions} value={"risk"} />
                      {
                        /*
                        {"riskcatalog_id":"R-AC-1","riskcatalog_grouping":"Access Control","riskcatalog_risk":"Inability to maintain individual accountability","riskcatalog_description":"The inability to maintain accountability (e.g., asset ownership, non-repudiation of actions or inactions, etc.). ","riskcatalog_function":"Protect"}
                        */
                        controlInfo && controlInfo.control_risk ?
                          <div className="mt-6 grid grid-cols-1 gap-4 mx-auto sm:grid-cols-2 xl:grid-cols-3">{controlInfo.control_risk.map((x) => <div>
                            <p className='mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{x?.riskcatalog_id}</p>
                            <div className="mt-1 flex gap-2 items-center">
                              <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{x?.riskcatalog_grouping}</Badge>
                              <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{x?.riskcatalog_function}</Badge>
                            </div>
                            <span className='text-tremor-title font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong'>{x?.riskcatalog_risk}</span>
                            <p className='mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{x?.riskcatalog_description}</p>
                          </div>
                          )}
                          </div>
                          : <strong className="font-medium leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                            No risk data to show
                          </strong>
                      }

                    </TabPanel>
                    <TabPanel>
                      <TabPanelIntro descriptions={descriptions} value={"threat"} />
                      {
                        controlInfo && controlInfo.control_threat ?
                          <div className="mt-6 grid grid-cols-1 gap-4 mx-auto sm:grid-cols-2 xl:grid-cols-3">{controlInfo.control_threat.map((x) => <div>
                            <p className='mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{x?.threatcatalog_id}</p>
                            <div className="mt-1 flex gap-2 items-center">
                              <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{x?.threatcatalog_grouping}</Badge>
                              {x?.threatcatalog_function ? <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{x?.threatcatalog_function}</Badge> : null}
                            </div>
                            <span className='text-tremor-title font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong'>{x?.threatcatalog_threat}</span>
                            <p className='mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{x?.threatcatalog_description}</p>
                          </div>
                          )}
                          </div>
                          : <strong className="font-medium leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                            No threat data to show
                          </strong>
                      }
                    </TabPanel>
                  </TabPanels>
                  :
                  <TabPanels>
                    <TabPanel>
                      <TabPanelIntro descriptions={descriptions} value={"objective"} />
                      {(a && a.answer_id) ?
                        <CtrlObjectives readOnly={readOnly} controlID={k} o={c.objectives} a={a} setA={setA} controlsAnswers={controlsAnswers} setControlsAnswers={setControlsAnswers} controls={Object.keys(controls).filter(z => controlsAnswers[z]?.answer_id)} allControlsData={controls} />
                        : <BannerMaturityLevelMissing />
                      }
                    </TabPanel>
                    <TabPanel>
                      <TabPanelIntro descriptions={descriptions} value={"levels"} />
                      <>
                        <div className='mt-6 flex flex-col gap-2 max-w-3xl'>
                          <h2 className='text-sm'>
                            Maturity Levels
                          </h2>
                          {c?.choices ? Object.values(c.choices).map(mlc => <>
                            <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">{mlc?.choice}</h3>
                            <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content whitespace-pre-line'>
                              {mlc?.description}
                            </p>
                          </>) : "No maturity levels found for this control."}
                        </div>
                      </>
                    </TabPanel>
                    <TabPanel>
                      <TabPanelIntro descriptions={descriptions} value={"information"} />
                      {(a && a.answer_id) ?
                        <>
                          <div className='mt-6 flex flex-col gap-2 max-w-3xl'>
                            <h2 className='text-sm'>
                              Maturity Level
                            </h2>
                            <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">{a?.answer}</h3>
                            <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content whitespace-pre-line'>
                              {a?.answer_description}
                            </p>
                          </div>
                          {a?.target_answer_id && a?.target_answer_description ?
                            <>
                              <Divider />
                              <div className='mt-6 flex flex-col gap-2 max-w-3xl'>
                                <h2 className='text-sm'>
                                  Target Maturity Level
                                </h2>
                                <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">{a?.target_answer ? a?.target_answer : a?.target_control_answer}</h3>
                                <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content whitespace-pre-line'>
                                  {a?.target_answer_description}
                                </p>
                              </div>
                            </> : null}
                        </>
                        : <BannerMaturityLevelMissing title="Set a Maturity Level for the Control to view Information" text={<>
                          To view the information associated with a particular control, you need to set the maturity level for that control.
                          <br />This allows you to see the information that correspond to the specified maturity level.
                        </>} />
                      }
                    </TabPanel>
                    <TabPanel>
                      <TabPanelIntro descriptions={descriptions} value={"risk"} />
                      {
                        /*
                        {"riskcatalog_id":"R-AC-1","riskcatalog_grouping":"Access Control","riskcatalog_risk":"Inability to maintain individual accountability","riskcatalog_description":"The inability to maintain accountability (e.g., asset ownership, non-repudiation of actions or inactions, etc.). ","riskcatalog_function":"Protect"}
                        */
                        controlInfo && controlInfo.control_risk ?
                          <div className="mt-6 grid grid-cols-1 gap-4 mx-auto sm:grid-cols-2 lg:grid-cols-3">{controlInfo.control_risk.map((x) => <div>
                            <p className='mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{x?.riskcatalog_id}</p>
                            <div className="mt-1 flex gap-2 items-center">
                              <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{x?.riskcatalog_grouping}</Badge>
                              <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{x?.riskcatalog_function}</Badge>
                            </div>
                            <span className='text-tremor-title font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong'>{x?.riskcatalog_risk}</span>
                            <p className='mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{x?.riskcatalog_description}</p>
                          </div>
                          )}
                          </div>
                          : <strong className="font-medium leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                            No risk data to show
                          </strong>
                      }

                    </TabPanel>
                    <TabPanel>
                      <TabPanelIntro descriptions={descriptions} value={"threat"} />
                      {
                        controlInfo && controlInfo.control_threat ?
                          <div className="mt-6 grid grid-cols-1 gap-4 mx-auto sm:grid-cols-2 lg:grid-cols-3">{controlInfo.control_threat.map((x) => <div>
                            <p className='mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{x?.threatcatalog_id}</p>
                            <div className="mt-1 flex gap-2 items-center">
                              <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{x?.threatcatalog_grouping}</Badge>
                              {x?.threatcatalog_function ? <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{x?.threatcatalog_function}</Badge> : null}
                            </div>
                            <span className='text-tremor-title font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong'>{x?.threatcatalog_threat}</span>
                            <p className='mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{x?.threatcatalog_description}</p>
                          </div>
                          )}
                          </div>
                          : <strong className="font-medium leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                            No threat data to show
                          </strong>
                      }
                    </TabPanel>
                  </TabPanels>
                }

                {
                  /*
                }
              <TabPanel>
                <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>
                  Descriptive text here...
                </p>
                <Divider>Remediation plan</Divider>
              </TabPanel>
              <TabPanel>
                <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>
                  Descriptive text here...
                </p>
                <Divider>Notes</Divider>
              </TabPanel>
              {
                */
                }
              </TabGroup>
              <LawPanel controlArticleFrameworks={controlInfo && controlInfo.control_article ? controlInfo.control_article.reduce((acc, article) => {
                const frameworkId = article.article_framework?.framework_id;
                const frameworkName = article.article_framework?.framework_name;
                //!!!!!!HERE DOING THE CHECK IF FRAMEWORK IS INCLUDED IN THIS CONTROL
                if (controls[k]?.frameworks?.indexOf(frameworkName) > -1) {
                  // Check if frameworkId exists in acc object, if not, initialize it as an empty array
                  if (!acc[frameworkId]) {
                    acc[frameworkId] = [];
                  }

                  // Push current article to the corresponding frameworkId array
                  acc[frameworkId].push(article);
                }
                return acc;
              }, {}) : null} language={language} isOpen={lawPanelOpen} setIsOpen={setLawPanelOpen} />
            </section>
            <Divider style={{
              marginTop: '0!important'
            }}
            />
            <div className='mt-6 flex gap-2 items-center justify-between'>
              <Button className="text-white bg-turquoise-600" onClick={() => setIsControlOpen(false)}>
                Back to all controls
              </Button>
              <span className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>{controlInfo.control_scfcontrol}</span>
              <div className='flex gap-2 flex-wrap'>
                {controls[k]?.frameworks?.map(f => <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{f}</Badge>)}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </>
  ) : "Loading";
}
function BannerMaturityLevelMissing({ title = null, text = null }) {
  return (
    <p className="leading-6 text-sm text-tremor-default text-tremor-content dark:text-dark-tremor-content mt-6">
      <div className='items-center flex gap-2'>
        <WarningAmberSharp className="text-turquoise-400" />
        <h2 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
          {title ? title : "Set a Maturity Level for the Control to view Objectives"}
        </h2>
      </div>
      {text ? text : <>
        To view the objectives associated with a particular control, you need to set the maturity level for that control.
        <br />This allows you to see the objectives that correspond to the specified maturity level.
      </>
      }
    </p>
  )
}

function TabPanelIntro({ value, descriptions }) {
  return (descriptions[value] && descriptions[value]?.text) ?
    <>
      <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>
        {descriptions[value]?.text}
      </p>
      <Divider>
        {descriptions[value]?.label}
      </Divider>
    </> : null;
}

export function CtrlSelectMaturityLevel({ readOnly, asRadio, darkMode, a, aID, c, callback, label, type }) {
  const getInitialChoiceKey = () => Object.keys(c).find((choice_id) => choice_id === aID);
  const getChoiceAnswered = (key) => (key && c[key] ? c[key] : null);

  const [choiceAnsweredKey, setChoiceAnsweredKey] = useState(getInitialChoiceKey());
  const [choiceAnswered, setChoiceAnswered] = useState(getChoiceAnswered(choiceAnsweredKey));
  const [value, setValue] = useState(choiceAnsweredKey);
  const [description, setDescription] = useState(choiceAnswered ? choiceAnswered.choice : null);
  const [shortDescription, setShortDescription] = useState(choiceAnswered ? choiceAnswered.answer || choiceAnswered.control_answer : null);

  useEffect(() => {
    const newChoiceKey = getInitialChoiceKey();
    const newChoiceAnswered = getChoiceAnswered(newChoiceKey);

    setChoiceAnsweredKey(newChoiceKey);
    setChoiceAnswered(newChoiceAnswered);
    setValue(newChoiceKey);
    setDescription(newChoiceAnswered ? newChoiceAnswered.choice : null);
    setShortDescription(newChoiceAnswered ? newChoiceAnswered.answer || newChoiceAnswered.control_answer : null);
  }, [aID, c]);
  return asRadio ? (
    <RadioGroup
      value={value}
      onChange={(x) => {
        if (readOnly) { toast.warning("In read only mode") } else {
          setValue(x)
          setDescription(c[x].description)
          setShortDescription(c[x].choice)
          callback(x)
        }
      }}
    >
      <div className="px-5 mt-4 mb-1 text-xs text-zinc-800 dark:text-zinc-300">{label}</div>
      {Object.entries(c)
        .sort((a, b) => {
          function getChoiceLabel(x) {
            return x[1]?.choice
          }
          if (!getChoiceLabel(a)) return 1;
          if (!getChoiceLabel(b)) return -1;
          return getChoiceLabel(a).localeCompare(getChoiceLabel(b));
        })
        .map((choice) => {
          const key = choice[0]
          const level = choice[1].level
          const choice_label = choice[1].choice
          return <Field
            aria-label="Levels"
            key={key}
            value={key}
          >
            <Radio
              value={key}
              className="mb-1 group relative flex cursor-pointer rounded-lg py-1 px-5 dark:text-white transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/20 data-[hover]:bg-white/10"
            >
              <div className="flex w-full items-center gap-2">
                <CircleSharp className="size-6 opacity-25 transition group-checked:opacity-100" />
                <div className="text-sm/6">
                  <p className="font-medium dark:text-white">{choice_label}</p>
                </div>
              </div> </Radio>
          </Field>
        }
        )
      }
    </RadioGroup>
  )
    : (
      <div key={label} className="w-full flex items-center gap-2 flex-row max-w-lg">
        <Select className="w-full" id="maturity_level" name="maturity_level" value={value} onValueChange={(x) => {
          if (readOnly) { toast.warning("In read only mode") } else {
            setValue(x)
            setDescription(c[x].description)
            setShortDescription(c[x].choice)
            callback(x)
          }
        }}>
          {Object.entries(c)
            .sort((a, b) => {
              function getChoiceLabel(x) {
                return x[1]?.choice
              }
              if (!getChoiceLabel(a)) return 1;
              if (!getChoiceLabel(b)) return -1;
              return getChoiceLabel(a).localeCompare(getChoiceLabel(b));
            })
            .map((choice) => {
              const key = choice[0]
              const level = choice[1].level
              const choice_label = choice[1].choice
              return <SelectItem key={key} value={key}>{choice_label}</SelectItem>
            })}
        </Select>
        {false &&
          <div className='flex gap-1 items-center'>
            <label htmlFor="maturity_level" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content text-left">{label}</label>
            <CtrlInfoDialog darkMode={darkMode} a={a} type={type} />
          </div>
        }
        {
          /*
        }
        <h5 className="mt-2 leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
          {shortDescription}
        </h5>
        <p className="line-clamp-3 text-tremor-default text-tremor-content dark:text-dark-tremor-content">{description}</p>
        {
          */
        }
      </div>
    );
}
export function CtrlObjectives({ readOnly, controlID, o, a, setA, controlsAnswers, setControlsAnswers, controls, allControlsData, MuscopeInviteAuth }) {
  const obs = Object.entries(o);
  // return <div className="mt-6 grid grid-cols-1 gap-4 sm:max-w-3xl sm:grid-cols-2">
  return <div className="mt-6 grid grid-cols-1 gap-4">
    {obs
      ? (obs.map((z) => {
        const objectiveKey = z[0]
        const oBody = z[1]
        return <Card
          key={objectiveKey}
          className="p-4 hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted"
        >
          <p className='mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>Objective {objectiveKey}</p>
          <h4 className="mt-4 mb-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
            {oBody.objective}
          </h4>
          <CtrlSelectObjective readOnly={readOnly} controlID={controlID} c={oBody.choices} oKey={objectiveKey} defaultAnswerID={a && a.objectives && a.objectives[objectiveKey] && a.objectives[objectiveKey].objective_answer_id ? a.objectives[objectiveKey].objective_answer_id : null} a={a} setA={setA} controlsAnswers={controlsAnswers} setControlsAnswers={setControlsAnswers} controls={controls} allControlsData={allControlsData} MuscopeInviteAuth={MuscopeInviteAuth} />
        </Card>
      }))
      : null
    }
  </div>
}
const objAnswersOrder = {
  "Met": 0,
  "Raggiunto": 0,
  "Cumplido": 0,
  "Erreicht": 0,
  "Atteint": 0,
  //
  "Not Met": 1,
  "Not Raggiunto": 1,
  "Non Raggiunto": 1,
  "No Cumplido": 1,
  "Nicht Erreicht": 1,
  "Non Atteint": 1,
  //
  "Not Applicable": 2,
  "Non Applicabile": 2,
  "Kompensierende Kontrolle": 2,
  "Non Applicable": 2,
  "No Aplicable": 2,
  "No Aplica": 2,
  //
  "Compensating Control": 3,
  "Controllo Compensativo": 3,
  "Control Compensatorio": 3,
  "Kompensierende Kontrolle": 3,
  "Contrôle Compensatoire": 3,
};
export function CtrlSelectObjective({ readOnly, controlID, c, oKey, defaultAnswerID, a, setA, controlsAnswers, setControlsAnswers, controls, allControlsData, MuscopeInviteAuth }) {
  /*   
  > "choices": {
            "69fff9ed-c281-41cd-bdc3-f4453cd742f3": {
                "choice": "Met"
            },
            "07e9576e-fbb1-4c94-90fa-dfd614bb4a10": {
                "choice": "Not Met"
            },
            "dfafe7d6-9b54-462f-a51d-8c89cb71d301": {
                "choice": "Not Applicable"
            },
            "ccf016f7-38e1-4d14-bd77-b7fdfa0c3cc7": {
                "choice": "Compensating Control"
            }
        }
    },
  */
  function updateObjective(selChoice, note, patch = false, compensatingControl) {
    let selectedChoice = selChoice;
    if (selectedChoice === undefined) {
      toast.error("Error getting the answer contact the support.")
    } else if (selectedChoice === 0) {
      // placeholder
      return false
    } else if (selectedChoice === 4) {
      // placeholder
      toast.info("Now select the compensating control to save the objective")
      return false
    } else if ((selectedChoice === null && patch === true) || selectedChoice) {
      const accountUUID = getAccountUUID();
      // compensating
      if (selectedChoice === "compensating") {
        selectedChoice = 4
      } else {
        setSelectedCompensatingControl(null)
      }
      // -1 because is an array
      // console.info(c, "Relaying on order of choices")
      console.info(c, "Relaying on objAnswersOrder")
      const getChoiceId = (c, objAnswersOrder, selectedChoice) => {
        if (selectedChoice) {
          // Calculate the corresponding order in objAnswersOrder
          const order = (parseInt(selectedChoice, 10) - 1);

          // Find all choices corresponding to the given order
          const choicesFiltered = Object.entries(objAnswersOrder)
            .filter(([key, value]) => value === order)

          // return the id of the first matching choice in the object c
          return Object.entries(c).find(zz => {
            // console.log('c-zz')
            // console.log(zz)
            // console.log('soa-choicesFiltered')
            // console.log(choicesFiltered)
            return choicesFiltered.find(qq => qq[0] === zz[1]?.choice)
          })[0]
        }

        return null; // No matching choice found
      };
      const givenAnswerID = getChoiceId(c, objAnswersOrder, selectedChoice)
      // POST
      const postObjectiveUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/objective/${oKey}/`;
      fetch(postObjectiveUrl, {
        body: givenAnswerID ? JSON.stringify(
          note ? {
            answer_id: givenAnswerID,
            compensating_control: compensatingControl,
            note: note
          } : {
            answer_id: givenAnswerID,
            compensating_control: compensatingControl,
          }
        ) : JSON.stringify({
          note: note
        }
        ),
        headers: {
          ...(MuscopeInviteAuth ? { "Muscope-Invite-Auth": MuscopeInviteAuth } : { Authorization: `Bearer ${localStorage.getItem("cysr_access")}` }),
          "content-type": "application/json",
        },
        method: patch ? "PATCH" : "POST"
      })
        .then((response) => response.json())
        .then((objectiveAnswerResponse) => {
          if (objectiveAnswerResponse && objectiveAnswerResponse.Msg) {
            let newA = { ...a }
            if (newA.objectives && newA.objectives[oKey]) {
              if (givenAnswerID) {
                newA.objectives[oKey].objective_answer_id = givenAnswerID;
                newA.objectives[oKey].objective_answer = c[givenAnswerID]?.choice;
              }
            } else {
              newA.objectives = { ...newA.objectives };
              if (givenAnswerID) {
                newA.objectives[oKey] = {
                  objective_answer_id: givenAnswerID,
                  objective_answer: c[givenAnswerID]?.choice
                }
              };
            }
            if (newA.objectives[oKey]) {
              if (note) {
                newA.objectives[oKey].objective_note = note
              }
              if (compensatingControl) {
                newA.objectives[oKey].compensating_control = compensatingControl
              }
            }
            let newControlsAnswers = { ...controlsAnswers }
            newControlsAnswers[controlID] = newA
            setControlsAnswers(newControlsAnswers)
            setA(newA);
            toast.success(objectiveAnswerResponse.Msg);
          } else {
            if (objectiveAnswerResponse.Error && objectiveAnswerResponse.Error === "An answer already exists for this objective") {
              updateObjective(selChoice, note, true, compensatingControl)
              // console.warn(objectiveAnswerResponse)
            } else {
              toast.error(objectiveAnswerResponse.note ? objectiveAnswerResponse.note : objectiveAnswerResponse.Error ? objectiveAnswerResponse.Error : JSON.stringify(objectiveAnswerResponse))
            }
          }
        })
        .catch((err) => {
          console.error(1, err)
          toast.error(err ? JSON.stringify(err) : "Could not update the objective.")
        });
    } else {
      toast.error("Error getting the answer contact the support.")
    }
  }

  const [noteOpen, setNoteOpen] = useState(false)
  const [noteText, setNoteText] = useState(a && a.objectives ? a?.objectives[oKey]?.objective_note : null)
  const [selectedCompensatingControl, setSelectedCompensatingControl] = useState(a && a.objectives ? a?.objectives[oKey]?.objective_compensating_control
    ? a?.objectives[oKey]?.objective_compensating_control : a?.objectives[oKey]?.objective_compensation : null)
  const [attachOpen, setAttachOpen] = useState(false)

  const [cSearch, setCSearch] = useState('')

  return (
    <>
      <TabGroup
        {
        ...readOnly ? {
          index: Object.keys(c).indexOf(defaultAnswerID) + 1
        } : {
          defaultIndex: Object.keys(c).indexOf(defaultAnswerID) + 1
        }
        }
        onIndexChange={(x) => {
          if (readOnly) {
            toast.warning("In read only mode")
            return false
          } else {
            // console.log(x, "heree")
            updateObjective(x, noteText)
          }
        }}
        className='flex flex-wrap gap-4 items-center justify-between'
      >
        <TabList variant="solid">
          {
            // Add empty value
          }
          <Tab
            //className='hidden'
            key={"-"} value={0}
          //aria-readonly disabled={a.objectives && a.objectives[defaultAnswerID]}
          >Not answered</Tab>
          {Object.entries(c).sort((ea, eb) => {
            return objAnswersOrder[ea[1].choice] - objAnswersOrder[eb[1].choice]
          }).map((choice, i) => {
            const key = choice[0]
            const v = choice[1].choice
            return <Tab key={key} value={i}><span className='absolute text-xs hidden'>{key}</span>{v}</Tab>
          })}
        </TabList>
        <TabPanels className='w-auto'>
          <TabPanel key={"-"} value={0} aria-readonly>
            <p className="text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
              Not answered yet
            </p>
          </TabPanel>
          {Object.entries(c).sort((ea, eb) => {
            return Object.keys(objAnswersOrder).indexOf(ea[1].choice) - Object.keys(objAnswersOrder).indexOf(eb[1].choice)
          }).map((choice, i) => {
            const key = choice[0]
            const v = choice[1].choice
            return <TabPanel className='mt-0' key={key} value={i}>
              <div className='flex items-end gap-4'>
                {
                  /*
                }
                <Button variant='light' className="outline-none text-left whitespace-nowrap truncate rounded-tremor-default focus:ring-2 transition duration-100 border h-8 px-3 py-1 shadow-tremor-input focus:border-tremor-brand-subtle focus:ring-tremor-brand-muted dark:shadow-dark-tremor-input dark:focus:border-dark-tremor-brand-subtle dark:focus:ring-dark-tremor-brand-muted pl-3 bg-tremor-background dark:bg-dark-tremor-background hover:bg-tremor-background-muted dark:hover:bg-dark-tremor-background-muted text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis border-tremor-border dark:border-dark-tremor-border" size='xs' onClick={() => { setAttachOpen(!attachOpen); setNoteOpen(false) }} icon={AttachFileSharp}>
                  Add evidence {attachOpen ? <KeyboardArrowUpSharp
                    className="h-6 w-6 text-tremor-content-strong dark:text-dark-tremor-content-strong"
                    aria-hidden={true}
                  /> :
                    <KeyboardArrowDownSharp
                      className="h-6 w-6 text-tremor-content-strong dark:text-dark-tremor-content-strong"
                      aria-hidden={true}
                    />}
                </Button>
                {
                  */
                }
                {i == 3 ?
                  // controls.length > 1 to also exclude same control
                  controls.length > 1 ? (
                    <>
                      <Popover className="relative z-[99999]">
                        <PopoverButton type='button'>
                          <Button variant='light' className="outline-none text-left whitespace-nowrap truncate rounded-tremor-default focus:ring-2 transition duration-100 border h-8 px-3 py-1 shadow-tremor-input focus:border-tremor-brand-subtle focus:ring-tremor-brand-muted dark:shadow-dark-tremor-input dark:focus:border-dark-tremor-brand-subtle dark:focus:ring-dark-tremor-brand-muted pl-3 bg-tremor-background dark:bg-dark-tremor-background hover:bg-tremor-background-muted dark:hover:bg-dark-tremor-background-muted text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis border-tremor-border dark:border-dark-tremor-border" size='xs' icon={EditSharp}>
                            {selectedCompensatingControl ? <span className='text-cyan-600 dark:text-cyan-500'>
                              {selectedCompensatingControl}
                            </span>
                              : "Set control"}
                          </Button>
                        </PopoverButton>
                        <PopoverPanel className="max-w-lg w-full shadow-lg rounded-lg z-[999999] ring-1 ring-tremor-ring dark:ring-dark-tremor-ring" style={{
                          position: 'fixed',
                          left: 0,
                          right: 0,
                          bottom: 'calc(50% - 200px)',
                          top: 'calc(50% - 200px)',
                          margin: 'auto'
                        }}>
                          <div className='space-y-4 bg-turquoise-100 dark:bg-turquoise-900 p-4 rounded-lg'>
                            <div
                              align="end"
                              className="flex flex-col gap-2 border-tremor-border dark:border-dark-tremor-border"
                            >
                              <p className='w-full text-left leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content'>Compensating Control</p>
                              <TextInput value={cSearch} onValueChange={setCSearch} className="max-w-xs mt-2" placeholder='Search controls' />
                              <RadioGroup value={selectedCompensatingControl} onChange={(y) => {
                                updateObjective('compensating', noteText, null, y)
                                setSelectedCompensatingControl(y)
                              }} className="border-tremor-border flex flex-col gap-2 dark:border-dark-tremor-border w-full max-h-[228px] overflow-scroll-y" placeholder="Select a control">
                                {controls.filter(cc => isFiltered(cc, cSearch)).map((c) => c !== controlID ? (
                                  <Radio
                                    key={c}
                                    className="group relative flex cursor-pointer rounded-lg bg-turquoise-50 dark:bg-white/5 py-3 px-2 text-black dark:text-white shadow-md transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/10"
                                    value={c}
                                  >
                                    <div className="flex w-full items-start gap-2">
                                      {
                                        /*
                                      }
                                      <item.icon
                                      className={classNames(
                                        item.disabled
                                        ? 'text-tremor-content-subtle/50'
                                        : 'text-tremor-content',
                                        'size-4 shrink-0',
                                      )}
                                      aria-hidden={true}
                                      />
                                      {
                                        */
                                      }
                                      {c != selectedCompensatingControl ?
                                        <RadioButtonUncheckedSharp className="my-0.5 size-6 fill-white opacity-100 transition group-checked:opacity-0" />
                                        : <RadioButtonCheckedSharp className="my-0.5 size-6 fill-white opacity-0 transition group-checked:opacity-100" />
                                      }
                                      {allControlsData && allControlsData[c] ? <ControlOption data={allControlsData[c]} control={c} answers={controlsAnswers} /> : null}
                                    </div>
                                  </Radio>
                                ) : null)}
                              </RadioGroup>
                            </div>
                            <div className="flex gap-4">
                              <CloseButton>
                                <Button variant='secondary' className="w-full text-white bg-turquoise-600">
                                  Close
                                </Button>
                              </CloseButton>
                            </div>
                          </div>
                          <div style={{
                            content: '',
                            background: 'hsl(194deg 12% 12% / 60%)',
                            position: 'fixed',
                            left: '-100px',
                            top: '-100px',
                            right: '-100px',
                            bottom: '-100px',
                            zIndex: '-1'
                          }} />
                        </PopoverPanel>
                      </Popover>
                    </>

                  )
                    : "No compensating controls selectable"
                  : null
                }
                <Button variant='light' className="outline-none text-left whitespace-nowrap truncate rounded-tremor-default focus:ring-2 transition duration-100 border h-8 px-3 py-1 shadow-tremor-input focus:border-tremor-brand-subtle focus:ring-tremor-brand-muted dark:shadow-dark-tremor-input dark:focus:border-dark-tremor-brand-subtle dark:focus:ring-dark-tremor-brand-muted pl-3 bg-tremor-background dark:bg-dark-tremor-background hover:bg-tremor-background-muted dark:hover:bg-dark-tremor-background-muted text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis border-tremor-border dark:border-dark-tremor-border" size='xs' onClick={() => { setNoteOpen(!noteOpen); setAttachOpen(false) }} icon={NoteAddSharp}>
                  {
                    //<AddCommentSharp className='h-4 w-4' />
                  }
                  {noteText ? "View" : "Add a"} note {noteOpen ? <KeyboardArrowUpSharp
                    className="h-6 w-6 text-tremor-content-strong dark:text-dark-tremor-content-strong"
                    aria-hidden={true}
                  /> :
                    <KeyboardArrowDownSharp
                      className="h-6 w-6 text-tremor-content-strong dark:text-dark-tremor-content-strong"
                      aria-hidden={true}
                    />}

                </Button>
              </div>
            </TabPanel>
          })}
        </TabPanels>
      </TabGroup>
      <div className={`w-full${noteOpen || attachOpen ? " mt-4 bg-black/20 p-4 rounded-md" : null}`}>
        {noteOpen ? <>
          <h4 className="dark:text-dark-tremor-content-strong text-base mt-3">
            Note
          </h4>
          <p className="mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
            Add an explanation
          </p>
          <CtrlTextareaObjectiveNote noteText={noteText} setNoteText={setNoteText} update={() => updateObjective(null, noteText, true)} />
        </>
          : null}
        {
          /*
        }
      {attachOpen ? <>
        <h4 className="dark:text-dark-tremor-content-strong text-base mt-3">
          Attach file
        </h4>
        <p className="mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
          Attach a file of evidence
        </p>
        <CtrlUploadControlEvidence data={{
          evidencerequest_id: "no id here",
          evidencerequest_documentation: "no doc here",
          evidencerequest_description: "no description here",
        }} />
      </>
        : null}
        {
          */
        }
      </div>
    </>
  )
}

function isFiltered(cc, cSearch) {
  return cSearch === "" || cc?.toUpperCase().indexOf(cSearch?.toUpperCase()) > -1;
}

function ControlOption({ data, control, answers }) {
  return <div className="text-left text-sm/6">
    <div className="flex gap-2 text-zinc-700 dark:text-zinc-300">
      <p className="font-medium text-black dark:text-white">{control}</p>
      <div aria-hidden="true">&middot;</div>
      <StatusBadge>{answers[control]?.answer_status}</StatusBadge>
    </div>
    <p className='text-sm mb-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong line-clamp-2'>{data?.question}</p>
    <p className='line-clamp-2'>{answers[control]?.answer}</p>
  </div>
}