/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";

import ComplyLayout from "layouts/cysr/invite-comply/ComplyLayout";
// @mui material components
import Grid from "@mui/material/Grid";
import Loader from "layouts/cysr/components/Loader";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { toast } from "sonner"
// Ctrl s
import "layouts/cysr/comply/style.css";
import { CtrlTableControls } from "layouts/cysr/comply/components/table-controls";
import { CtrlDialogControl } from 'layouts/cysr/comply/components/dialog-control';
import { CtrlPieQuestionnaire } from 'layouts/cysr/comply/components/pie-questionnaire';
import { Badge, Divider, ProgressBar, Select, SelectItem } from '@tremor/react';
import { ComplySankeyCharts } from 'layouts/cysr/comply/components/comply-sankey-charts';
// Ctrl e
import Card from "@mui/material/Card";
import cysrConfig from "layouts/cysr/config";
import { CheckCircleSharp, CheckSharp, LanguageSharp, PersonAddAlt1Sharp, StopCircleSharp, StopSharp } from '@mui/icons-material';
import { ComplyDownloadReport } from 'layouts/cysr/comply/components/download-report';

import { StatusBadge } from 'layouts/cysr/comply/components/table-controls';
import { QuestionnaireActions } from 'layouts/cysr/comply/components/questionnaire-actions';
import { ComplyInvite } from 'layouts/cysr/comply/components/comply-invite';
import { ComplyCredits } from '../../comply/components/comply-credits';
// import { ComplyStatsOverview } from 'layouts/cysr/comply/components/comply-stats-overview';


// const MuscopeInviteAuth = "b6de35149db511ef95970242ac13000517664c1fe2484759a40e994cb6d40538";
//const MuscopeInviteAuth = "48eebdb69db611ef86060242ac130005f9539f9b9b694ec78c775cfd5991e1ab";
const MuscopeInviteAuth = sessionStorage.getItem("mu-in-auth");

const queryString = window.location.search; // Get the query string
const urlParams = new URLSearchParams(queryString); // Create a URLSearchParams object
const accountUUID = urlParams.get('company'); // Get the value of 'company'

const statuses = [
    {
        label: "Unanswered",
        value: "UNANSWERED",
    },
    {
        label: "Answered",
        value: "ANSWERED",
    },
    {
        label: "Draft",
        value: "DRAFT"
    }
]

export default function InviteComplyQuestionnaire() {
    // get hashtag
    let questionnaireUUID = 0;
    // extract id
    if (window.location.hash !== undefined) {
        questionnaireUUID = window.location.hash.replace("#", "");
    }
    // const accountUUID = window.location.pathname.substring(
    //     window.location.pathname.indexOf("/company/") + "/company/".length,
    //     window.location.pathname.lastIndexOf("/")
    // );
    const [controller] = useMaterialUIController();

    const [sankeyExpanded, setSankeyExpanded] = useState(false);
    const { darkMode } = controller;
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("ctrl-language") ? localStorage.getItem("ctrl-language") : "en");
    // control s
    const [isControlOpen, setIsControlOpen] = useState(false);
    const [controlInfo, setControlInfo] = useState(false);
    const [controlsSorting, setControlsSorting] = useState([])
    function loadControl(key, language = selectedLanguage) {
        /*
        const accountUUID = window.location.pathname.substring(
            window.location.pathname.indexOf("/company/") + "/company/".length,
            window.location.pathname.lastIndexOf("/")
        );
        // /{version}/company/{company_id}/control/{control_scf_id}
        */
        // GET
        const getControlUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/control/${key}/`;
        //const getControlUrl = `${cysrConfig().ctrl_api_base_url}control/${key}/`;
        fetch(getControlUrl, {
            /*
            body: JSON.stringify({
              answer_id: givenAnswerID
            }),
            */
            headers: {
                // Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                "Muscope-Invite-Auth": MuscopeInviteAuth,
                'Accept-Language': language,
                // "content-type": "application/json",
            },
            // method: "GET"
        })
            .then((response) => response.json())
            .then((getControlResponse) => {
                if (getControlResponse.Error) {
                    // request without company.. s
                    fetch(`${cysrConfig().ctrl_api_base_url}control/${key}/`, {
                        headers: {
                            // Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                            "Muscope-Invite-Auth": MuscopeInviteAuth,
                            'Accept-Language': language,
                            // "content-type": "application/json",
                        },
                        // method: "GET"
                    })
                        .then((companylessResponse) => companylessResponse.json())
                        .then((getControlCompanylessResponse) => {
                            setControlInfo(getControlCompanylessResponse)
                        })
                        .catch((err) => {
                            console.error(2, err)
                        });

                    // request without company.. e
                } else {
                    setControlInfo(getControlResponse)
                }
            })
            .catch((err) => {
                console.error(1, err)
            });
    }
    function loadStats(qStats, setQStats, questionnaireID, typology = 'risk', language = selectedLanguage) {
        // GET
        const getStatsUrl = `${cysrConfig().ctrl_api_base_url}questionnaire/${questionnaireID}/${typology}-stats`;
        //const getControlUrl = `${cysrConfig().ctrl_api_base_url}control/${key}/`;
        fetch(getStatsUrl, {
            /*
            body: JSON.stringify({
              answer_id: givenAnswerID
            }),
            */
            headers: {
                // Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                "Muscope-Invite-Auth": MuscopeInviteAuth,
                'Accept-Language': language,
                // "content-type": "application/json",
            },
            // method: "GET"
        })
            .then((response) => response.json())
            .then((getStatsResponse) => {
                if (getStatsResponse.Error) {
                    toast.error(getStatsResponse.Error)
                } else {
                    let newStats = qStats
                    newStats[typology] = getStatsResponse
                    setQStats(newStats)
                }
            })
            .catch((err) => {
                console.error(1, err)
            });
    }
    // control e
    /*
    // filter test s
    const params = new URLSearchParams(window.location.search);
    let activeTags = [];
    try {
        activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
    } catch (error) {
        console.error("Error in setting active tags:")
        console.error(error)
    }
    // filter test e
    */

    // Our State.
    const [qStats, setQStats] = useState([]);
    const [companyEvidences, setCompanyEvidences] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [views, setViews] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [controls, setControls] = useState([]);
    const [questionnaire, setQuestionnaire] = useState(null);
    const [questionnaires, setQuestionnaires] = useState([]);
    // /{v}/questionnaire/{id}
    // const getQuestionnaireUrl = `${cysrConfig().ctrl_api_base_url}questionnaire/${questionnaireUUID}`;
    // const getQuestionnaireUrl = `${cysrConfig().ctrl_api_base_url}questionnaire-improved/${questionnaireUUID}`;

    // const getQuestionnaireUrl = `${cysrConfig().ctrl_api_base_url}invited-user/questionnaire`;
    // /v1/invited-user/questionnaire/?questionnaire_id=<questionnaire_id>
    const getQuestionnaireUrl = `${cysrConfig().ctrl_api_base_url}invited-user/questionnaire?questionnaire_id=${questionnaireUUID}`;
    // /{v}/questionnaire/{id}/controls
    // const getQuestionnaireControlsUrl = `${cysrConfig().ctrl_api_base_url}questionnaire/${questionnaireUUID}/controls`;
    useEffect(() => {
        const pageTitle = "Comply";
        document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
        if (MuscopeInviteAuth) {
            fetch(getQuestionnaireUrl, {
                headers: {
                    // Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                    "Muscope-Invite-Auth": MuscopeInviteAuth,
                    'Accept-Language': selectedLanguage,
                },
            })
                .then((response) => response.json())
                .then(async (questionnaireResponse) => {
                    const q = questionnaireResponse[questionnaireUUID];

                    // loadStats(qStats, setQStats, questionnaireUUID, 'risk', selectedLanguage)
                    // loadStats(qStats, setQStats, questionnaireUUID, 'threat', selectedLanguage)

                    setViews({
                        answers: q.answers,
                        controls: q.controls
                        // frameworks: frameworksResponse
                    });
                    setAnswers(q.answers)
                    setControls(q.controls)
                    setQuestionnaire(q)
                })
                .catch((err) => {
                    console.error(2, err)
                    setViews({
                        answers: null,
                        controls: null,
                        // frameworks: null
                    });
                });
        } else {
            setViews({
                answers: null,
                controls: null,
                // frameworks: null
            });
        }
    }, [selectedLanguage]);
    const callArtifacts = false;
    useEffect(() => {
        if (callArtifacts) {
            if (localStorage.getItem("cysr_useraccountroles")) {
                fetch(`${cysrConfig().ctrl_api_base_url}company/${accountUUID}/artifacts`, {
                    headers: {
                        // Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                        "Muscope-Invite-Auth": MuscopeInviteAuth,
                        'Accept-Language': selectedLanguage,
                    },
                })
                    .then((response) => response.json())
                    .then(async (evidencesResponse) => {
                        setCompanyEvidences(evidencesResponse)
                    })
                    .catch((err) => {
                        console.error(3, err)
                        setCompanyEvidences(null)
                    });
            }
        }
    }, []);
    return (
        <ComplyLayout>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {views && views.controls ?
                            <Card>
                                <div className={darkMode ? "dark" : "light"}>
                                    <div className='px-4 py-4'>
                                        <div className="mb-6 flex gap-2 items-center text-turquoise-700 dark:text-turquoise-400">
                                            <a href="/cysr/invite-comply" className='flex gap-1 items-center'>
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355L3.70711 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H3.70711L6.85355 11.1464C7.04882 11.3417 7.04882 11.6583 6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.14645 7.85355C1.95118 7.65829 1.95118 7.34171 2.14645 7.14645L6.14645 3.14645C6.34171 2.95118 6.65829 2.95118 6.85355 3.14645Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                                                <span className="text-sm text-turquoise-700 dark:text-turquoise-400">
                                                    Back to questionnaire list
                                                </span>
                                            </a>
                                            <div className='ml-auto flex gap-2 max-w-xs w-full'>
                                                <p className="text-zinc-500 dark:text-zinc-300">
                                                    <LanguageSharp />
                                                </p>
                                                <Select value={selectedLanguage} onValueChange={
                                                    (x) => {
                                                        localStorage.setItem("ctrl-language", x)
                                                        setSelectedLanguage(x)
                                                    }
                                                }>
                                                    <SelectItem value="en">English</SelectItem>
                                                    <SelectItem value="es">Español</SelectItem>
                                                    <SelectItem value="de">Deutsch</SelectItem>
                                                    <SelectItem value="fr">Français</SelectItem>
                                                    <SelectItem value="it">Italiano</SelectItem>
                                                </Select>
                                            </div>
                                        </div>
                                        <Divider />
                                        <div className="mb-6 flex gap-2 items-start text-turquoise-700 dark:text-turquoise-400">
                                            {questionnaire
                                                ?
                                                <div>
                                                    {questionnaire.approved
                                                        ? <div className='dark:text-dark-tremor-content-emphasis text-xs flex items-center'><CheckSharp className='!h-6 !w-6 text-green-400' />Approved</div>
                                                        : <div className='dark:text-dark-tremor-content-emphasis text-xs flex items-center'><StopSharp className='!h-6 !w-6 text-red-400' />Disapproved</div>
                                                    }
                                                    <div className='flex gap-2 items-center'>
                                                        <QuestionnaireActions
                                                            accountUUID={accountUUID}
                                                            questionnaireUUID={questionnaireUUID}
                                                            questionnaire={questionnaire}
                                                            setQuestionnaire={setQuestionnaire}
                                                            statuses={[
                                                                {
                                                                    label: "Deficient",
                                                                    value: "Deficient",
                                                                },
                                                                {
                                                                    label: "In Place",
                                                                    value: "In Place",
                                                                },
                                                                {
                                                                    label: "Monitored",
                                                                    value: "Monitored",
                                                                },
                                                                {
                                                                    label: "Ready for Review",
                                                                    value: "Ready for Review",
                                                                }
                                                            ]}
                                                            ghost={true}
                                                            className="z-[999999] relative inline-flex items-center"
                                                            label={
                                                                null
                                                            }>
                                                        </QuestionnaireActions>
                                                        <StatusBadge defaultString="Deficient" status={questionnaire.status ? questionnaire.status : "Deficient"} />
                                                        <h2 className="text-black text-2xl dark:text-white text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">{questionnaire.name}</h2>
                                                    </div>
                                                    <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>Last edited on {questionnaire.modified_at}</p>
                                                    <div className='flex gap-2 flex-wrap mb-4'>
                                                        {
                                                            questionnaire.frameworks.map(f => <Badge className='bg-turquoise-200 dark:bg-turquoise-800 text-turquoise-800 dark:text-turquoise-200 rounded-md ring-1 ring-tremor-ring dark:ring-dark-tremor-ring' size="sm">{f.framework_name}</Badge>)
                                                        }
                                                    </div>{answers && (Object.values(answers))
                                                        ?
                                                        <div className='max-w-xs w-full gap-2 flex items-center'>
                                                            <ProgressBar value={(Object.values(answers)?.filter(z => z.answer_id).length /
                                                                //Object.values(answers)?.length
                                                                Object.keys(controls)?.length
                                                                * 100)} color="teal" />
                                                            <p className="whitespace-nowrap text-tremor-default text-tremor-content dark:text-dark-tremor-content flex items-center justify-between">
                                                                <span>
                                                                    {(Object.values(answers)?.filter(z => z.answer_id).length /
                                                                        Object.keys(controls)?.length
                                                                        // Object.values(answers)?.length
                                                                        * 100).toFixed(0)}% &bull; <strong className='font-medium text-black dark:text-white'>
                                                                        {Object.values(answers)?.filter(z => z.answer_id).length} of {
                                                                            // Object.values(answers)?.length
                                                                            Object.keys(controls)?.length
                                                                        }
                                                                    </strong>
                                                                </span>
                                                            </p>
                                                        </div>
                                                        : null}
                                                </div>
                                                : null
                                            }
                                            <div className='ml-auto max-w-lg w-full text-right'>

                                                {false &&
                                                    <div className='my-2 max-w-xs w-full text-right'>
                                                        <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>Status</p>
                                                        <Select value={questionnaire.status ? questionnaire.status : "Deficient"} onValueChange={
                                                            (x) => {
                                                                let newQ = { ...questionnaire };
                                                                newQ.status = x;
                                                                setQuestionnaire(newQ)
                                                                fetch(`${cysrConfig().ctrl_api_base_url}company/${accountUUID}/questionnaire/${questionnaireUUID}`, {
                                                                    body: JSON.stringify({
                                                                        status: x
                                                                    }),
                                                                    headers: {
                                                                        // Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                                        "Muscope-Invite-Auth": MuscopeInviteAuth, "content-type": "application/json",
                                                                    },
                                                                    method: "PATCH"
                                                                })
                                                                    .then((response) => response.json())
                                                                    .then(() => {
                                                                        toast.success("Status updated")
                                                                    })
                                                                    .catch((err) => {
                                                                        console.error(1, err)
                                                                    })
                                                            }
                                                        }>
                                                            <SelectItem value="Deficient">Deficient</SelectItem>
                                                            <SelectItem value="In Place">In Place</SelectItem>
                                                            <SelectItem value="Ready for Review">Ready For Review</SelectItem>
                                                            <SelectItem value="Monitored">Monitored</SelectItem>
                                                        </Select>
                                                    </div>
                                                }
                                                <div className='inline-flex gap-2 items-center ml-auto max-w-lg w-full text-right'>
                                                    {/* <MDButton style={{ width: '100%', ...cysrConfig().button_style }} size="medium" onClick={() => {
                                                        const newApprovedVal = !questionnaire.approved
                                                        fetch(`${cysrConfig().ctrl_api_base_url}company/${accountUUID}/questionnaire/${questionnaireUUID}`, {
                                                            body: JSON.stringify({
                                                                approved: newApprovedVal
                                                            }),
                                                            headers: {
                                                                // Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                                                "Muscope-Invite-Auth": MuscopeInviteAuth, "content-type": "application/json",
                                                            },
                                                            method: "PATCH"
                                                        })
                                                            .then((response) => response.json())
                                                            .then(() => {
                                                                let newQ = { ...questionnaire };
                                                                newQ.approved = newApprovedVal;
                                                                setQuestionnaire(newQ)
                                                            })
                                                            .catch((err) => {
                                                                console.error(1, err)
                                                            })
                                                    }} type='button' title='Set questionnaire approval'>
                                                        {questionnaire.approved
                                                            ? <>
                                                                <StopCircleSharp style={cysrConfig().button_icon_left_style} className='!h-6 !w-6' />
                                                                Disapprove
                                                            </>
                                                            : <>
                                                                <CheckCircleSharp style={cysrConfig().button_icon_left_style} className='!h-6 !w-6' />
                                                                Approve
                                                            </>
                                                        }
                                                    </MDButton> */}
                                                    {/* <ComplyInvite className='inline-flex' darkMode={darkMode} language={selectedLanguage} questionnaireID={questionnaireUUID} controls={controls} accountUUID={accountUUID}>
                                                    <MDButton style={{ margin: 0, width: '100%', ...cysrConfig().button_style }} size="medium">
                                                        <PersonAddAlt1Sharp style={cysrConfig().button_icon_left_style} className='!h-6 !w-6' />
                                                        Invites
                                                    </MDButton>
                                                </ComplyInvite> */}
                                                    <ComplyDownloadReport language={selectedLanguage} questionnaireID={questionnaireUUID} />
                                                </div>
                                                <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>Toggle the Questionnaire Approval status, Invite or Download a Report <br />(the file will be generated in the current language: {selectedLanguage}).</p>
                                            </div>
                                        </div>
                                        {
                                            false && (
                                                <div>
                                                    <h3 className="text-black dark:text-white text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">List of controls</h3>
                                                    <p className='text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>
                                                        Descriptive text here... add filters + laws</p>
                                                </div>
                                            )
                                        }
                                        <div className='flex gap-6'>
                                            <CtrlPieQuestionnaire controls={controls} controlsAnswers={answers} />
                                        </div>
                                        <div style={{
                                            marginTop: "-52px"
                                        }}>
                                            {
                                                sankeyExpanded
                                                    ?
                                                    <>
                                                        <MDButton onClick={() => setSankeyExpanded(false)} style={cysrConfig().button_style} size="medium">
                                                            Hide Charts
                                                            <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} />
                                                        </MDButton>
                                                        <p className='mt-6 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>
                                                            By Function Group
                                                        </p>
                                                        <ComplySankeyCharts noSecondLevel={true} controls={controls} controlsAnswers={answers} startingProperty="function_group" />
                                                        <p className='mt-6 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>
                                                            By Domain
                                                        </p>
                                                        <ComplySankeyCharts noSecondLevel={true} controls={controls} controlsAnswers={answers} startingProperty="domain" />
                                                    </>
                                                    : <MDButton onClick={() => setSankeyExpanded(true)} style={cysrConfig().button_style} size="medium">
                                                        View Charts
                                                        <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                                                    </MDButton>
                                            }
                                        </div>
                                        {/* {qStats ?
                                        <div className='flex flex-col gap-4'>
                                            <ComplyStatsOverview data={qStats?.risk} />
                                            <ComplyStatsOverview data={qStats?.threat} />
                                        </div>
                                        : null
                                    } */}
                                        {
                                            controls ? <>
                                                {isControlOpen ?
                                                    <CtrlDialogControl MuscopeInviteAuth={MuscopeInviteAuth} controlInfo={controlInfo} loadControl={loadControl} companyEvidences={companyEvidences} setCompanyEvidences={setCompanyEvidences} prevK={
                                                        controlsSorting ? controlsSorting[controlsSorting.indexOf(isControlOpen) - 1] :
                                                            Object.keys(controls)[Object.keys(controls).indexOf(isControlOpen) - 1]
                                                    } nextK={
                                                        controlsSorting ? controlsSorting[controlsSorting.indexOf(isControlOpen) + 1] :
                                                            Object.keys(controls)[Object.keys(controls).indexOf(isControlOpen) + 1]
                                                    } isControlOpen={isControlOpen} setIsControlOpen={setIsControlOpen} darkMode={darkMode} item={Object.entries(controls).find(y => y[0] === isControlOpen)} answers={answers[isControlOpen]} controlsAnswers={answers} setControlsAnswers={setAnswers} statuses={statuses} controls={controls} language={selectedLanguage} />
                                                    : null}
                                                <CtrlTableControls setControlsSorting={setControlsSorting} language={selectedLanguage} loadControl={loadControl} setIsControlOpen={setIsControlOpen} darkMode={darkMode} answers={answers} setAnswers={setAnswers} controls={controls} // setControls={setControls}
                                                />
                                            </>
                                                : <p>No controls found.</p>
                                        }
                                    </div>
                                </div>
                            </Card>
                            :
                            <Loader />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <ComplyCredits />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </ComplyLayout>
    );
}

/* eslint-disable */
// prettier-ignore
