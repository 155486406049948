/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect, useRef } from "react";


import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";

import {
    Card as CardIn,
    CardHeader,
    CardTitle,
    CardDescription,
    CardContent,
} from "layouts/cysr/components/ui/card";


import cysrConfig, { process } from "layouts/cysr/config";

import Loader from "layouts/cysr/components/Loader";


import { useMaterialUIController } from "context";
import { ImportantMessage } from "layouts/cysr/components/ImportantMessage";

//+

import {
    AreaChart,
    Badge,
    Bold,
    Card,
    Col,
    Divider,
    Flex,
    Grid,
    Icon,
    List,
    ListItem,
    Metric,
    Text,
    ProgressBar,
    Subtitle,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    Title,
    MarkerBar,
    LineChart,
    BarChart
} from "@tremor/react";
import {
    GppMaybeSharp,
    LeakRemoveSharp,
    SpeedSharp,
    InfoSharp
} from "@mui/icons-material";
import ChartTree from "./chart/tree";
import { ParentSize } from "@visx/responsive";
import { toast } from "sonner";

const data = [
    {
        Month: "Jan 21",
        Sales: 2890,
        Profit: 2400,
    },
    {
        Month: "Feb 21",
        Sales: 1890,
        Profit: 1398,
    },
    // ...
    {
        Month: "Jan 22",
        Sales: 3890,
        Profit: 2980,
    },
];
const digitsAfterCommaToFixed = 1
/*
const valueFormatter = (number) =>
    number < 1000
        ? `€${Intl.NumberFormat("it").format(number.toFixed(digitsAfterCommaToFixed)).toString()}`
        : number < 1000000
            ? `€${Intl.NumberFormat("it").format((number / 1000).toFixed(digitsAfterCommaToFixed)).toString()}K`
            : number < 1000000000
                ? `€${Intl.NumberFormat("it").format((number / 1000000).toFixed(digitsAfterCommaToFixed)).toString()}M`
                : `€${Intl.NumberFormat("it").format((number / 1000000000).toFixed(digitsAfterCommaToFixed)).toString()}B`;
*/
export const valueFormatter = (number, digitsAfterCommaToFixed = 2, full = false) => {
    if (number === undefined || number === "" || isNaN(number)) {
        return `${number}`
    }
    number = parseFloat(number)
    if (full) {
        return `€${Intl.NumberFormat("it").format(number.toFixed(digitsAfterCommaToFixed)).toString()}`
    }
    return number < 1000
        ? `€${Intl.NumberFormat("it").format(number.toFixed(digitsAfterCommaToFixed)).toString()}`
        : number < 1000000
            ? `€${Intl.NumberFormat("it").format((number / 1000).toFixed(digitsAfterCommaToFixed)).toString()}K`
            : number < 1000000000
                ? `€${Intl.NumberFormat("it").format((number / 1000000).toFixed(digitsAfterCommaToFixed)).toString()}M`
                : number < 1000000000000
                    ? `€${Intl.NumberFormat("it").format((number / 1000000000).toFixed(digitsAfterCommaToFixed)).toString()}B`
                    : number < 1000000000000000
                        ? `€${Intl.NumberFormat("it").format((number / 1000000000000).toFixed(digitsAfterCommaToFixed)).toString()}T`
                        : `€${Intl.NumberFormat("it").format((number / 1000000000000000).toFixed(digitsAfterCommaToFixed)).toString()}Qa`;
};
export const inverseValueFormatter = (formattedNumber, full = false) => {
    const value = formattedNumber.replace(/[^\d.-]/g, '');
    if (full) {
        return parseFloat(value)
    }
    const suffix = formattedNumber.replace(/[^a-zA-Z]/g, '');
    switch (suffix) {
        case 'K':
            return parseFloat(value) * 1000;
        case 'M':
            return parseFloat(value) * 1000000;
        case 'B':
            return parseFloat(value) * 1000000000;
        case 'T':
            return parseFloat(value) * 1000000000000;
        case 'Qa':
            return parseFloat(value) * 1000000000000000;
        default:
            if (value === undefined || value === "" || isNaN(value)) {
                return `${value}`
            }
            return parseFloat(value);
    }
};

// € 104,3 K
// const badgeNumberMaxLengthForDecimal = 7;
// € 104 K
// export const badgeNumberMaxLengthForDecimal = 6;
// € 70,6 K > 71 K
export const badgeNumberMaxLengthForDecimal = 5;
const showGoToQuestions = true;

export function ChartRisk() {
    return (
        <Card>
            <Title>Performance</Title>
            <Text>Comparison between Sales and Profit</Text>
            <AreaChart
                className="mt-4 h-80"
                data={data}
                categories={["Sales", "Profit"]}
                index="Month"
                colors={["indigo", "fuchsia"]}
                showLegend={false}
                yAxisWidth={60}
                valueFormatter={valueFormatter}
            />
        </Card>
    );
}


function Risk() {

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const accountUUID = window.location.pathname.substring(
        window.location.pathname.indexOf("/company/") + "/company/".length,
        window.location.pathname.lastIndexOf("/")
    );
    // filter test s
    const params = new URLSearchParams(window.location.search);
    let activeTags = [];
    try {
        activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
    } catch (error) {
        console.error("Error in setting active tags:")
        console.error(error)
    }
    // filter test e

    const detailRef = useRef()
    const methodologyRef = useRef()

    // Our State.
    const [views, setViews] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [biggestMaxValue, setBiggestMaxValue] = useState(0);

    // Tutti gli asset di un account (/asset/) senza UUID?
    if (localStorage.getItem("cysr_useraccountroles") === null) {
        window.location.href = "/cysr/login";
    }

    function getPercentageRange(value, maxValue) {
        // value of DATA on 100
        //  DATA : maxValue / x : 100,
        return ((value * 100) / maxValue).toFixed(2);
    }
    const getCallUrl = `${cysrConfig().api_base_url}risk/${accountUUID}/`;
    useEffect(() => {
        const pageTitle = "Risk";
        document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
        if (localStorage.getItem("cysr_useraccountroles")) {
            //let groupSelectData = [];
            // call table of asset + call table of checks
            fetch(getCallUrl, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                },
            })
                .then((response) => response.json())
                .then((riskResponse) => {
                    let dataFromRiskResponse = [];
                    for (const key in riskResponse) {
                        if (Object.hasOwnProperty.call(riskResponse, key)) {
                            const element = riskResponse[key].CUSTOM;


                            dataFromRiskResponse.push(
                                {
                                    "riskData": {
                                        "date": "2024-02-09",
                                        "name": key ? key : element.riskData?.name,
                                        "risk": {
                                            "minValue": element.riskData?.badge?.risk?.minValue,
                                            "averageValue": element.riskData?.badge?.risk?.averageValue,
                                            "maxValue": element.riskData?.badge?.risk?.maxValue
                                        },
                                        "badge": {
                                            "lef": {
                                                "minValue": element.riskData?.badge?.lef?.minValue,
                                                "averageValue": element.riskData?.badge?.lef?.averageValue,
                                                "maxValue": element.riskData?.badge?.lef?.maxValue
                                            },
                                            "lm": {
                                                "minValue": element.riskData?.lm?.minValue,
                                                "averageValue": element.riskData?.lm?.averageValue,
                                                "maxValue": element.riskData?.lm?.maxValue
                                            }
                                        }
                                    },
                                    "fairTree": element.report_body.fairTree,
                                    "lossCurve": element.report_body.lossCurve,
                                    "primaryCost": element.report_body.primaryCost,
                                    "secondaryCost": element.report_body.secondaryCost,
                                    "riskDistribution": element.report_body.riskDistribution,
                                    /*,
                                    "primaryCost": {
                                        "primaryCostRange": [
                                            {
                                                "title": "Minimum",
                                                "metric": 100000
                                            },
                                            {
                                                "title": "Most likely",
                                                "metric": 2000000
                                            },
                                            {
                                                "title": "Maximum",
                                                "metric": 3000000
                                            }
                                        ],
                                        "primaryCostDetails": [
                                            {
                                                "name": "Recovery cost",
                                                "minValue": 4000000,
                                                "averageValue": 5000000,
                                                "maxValue": 19000000
                                            },
                                            {
                                                "name": "Business interruption",
                                                "minValue": 4000000,
                                                "averageValue": 5000000,
                                                "maxValue": 19000000
                                            },
                                            {
                                                "name": "Cyber extortion",
                                                "minValue": 4000000,
                                                "averageValue": 5000000,
                                                "maxValue": 19000000
                                            }
                                        ]
                                    },
                                    "secondaryCost": {
                                        "secondaryCostRange": [
                                            {
                                                "title": "Minimum",
                                                "metric": 100000
                                            },
                                            {
                                                "title": "Most likely",
                                                "metric": 2000000
                                            },
                                            {
                                                "title": "Maximum",
                                                "metric": 3000000
                                            }
                                        ],
                                        "secondaryCostDetails": [
                                            {
                                                "name": "Post Breach Security Improvements",
                                                "description": "Security improvements undertaken voluntarily after a breach.",
                                                "minValue": 4000000,
                                                "averageValue": 9000000,
                                                "maxValue": 10000000
                                            }
                                        ]
                                    },
                                    "riskDistribution": [
                                        {
                                            "price": 0,
                                            "simulations": 0
                                        },
                                        {
                                            "price": 10000,
                                            "simulations": 10
                                        },
                                        {
                                            "price": 15000,
                                            "simulations": 20
                                        },
                                        {
                                            "price": 16000,
                                            "simulations": 15
                                        },
                                        {
                                            "price": 30000,
                                            "simulations": 50
                                        },
                                        {
                                            "price": 45000,
                                            "simulations": 60
                                        },
                                        {
                                            "price": 60000,
                                            "simulations": 30
                                        },
                                        {
                                            "price": 75000,
                                            "simulations": 20
                                        },
                                        {
                                            "price": 90000,
                                            "simulations": 2
                                        }
                                    ],
                                    "lossCurve": [
                                        [
                                            {
                                                "cost": 0,
                                                "percentage": 100
                                            },
                                            {
                                                "cost": 50000,
                                                "percentage": 95
                                            },
                                            {
                                                "cost": 100000,
                                                "percentage": 60
                                            },
                                            {
                                                "cost": 150000,
                                                "percentage": 10
                                            },
                                            {
                                                "cost": 200000,
                                                "percentage": 0
                                            },
                                            {
                                                "cost": 250000,
                                                "percentage": 0
                                            }
                                        ]
                                    ],
                                    "fairTree": {
                                        "name": "Risk",
                                        "shortName": "R",
                                        "minValue": 8000000,
                                        "averageValue": 14000000,
                                        "maxValue": 29000000,
                                        "children": [
                                            {
                                                "name": "Loss Event Frequency",
                                                "shortName": "LEF",
                                                "minValue": 4000000,
                                                "averageValue": 9000000,
                                                "maxValue": 19000000,
                                                "children": [
                                                    {
                                                        "name": "Threat Event Frequency",
                                                        "shortName": "TEF",
                                                        "minValue": 0.5,
                                                        "averageValue": 0.5,
                                                        "maxValue": 1,
                                                        "children": [
                                                            {
                                                                "name": "Contact Frequency",
                                                                "shortName": "C",
                                                                "minValue": 0,
                                                                "averageValue": 0,
                                                                "maxValue": 0
                                                            },
                                                            {
                                                                "name": "Probability of Action",
                                                                "shortName": "A",
                                                                "minValue": 0,
                                                                "averageValue": 0,
                                                                "maxValue": 0
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "name": "Vulnerability",
                                                        "shortName": "V",
                                                        "minValue": 0.1,
                                                        "averageValue": 0.2,
                                                        "maxValue": 0.5,
                                                        "children": [
                                                            {
                                                                "name": "Threat Capability",
                                                                "shortName": "TC",
                                                                "minValue": 0.1,
                                                                "averageValue": 0.62,
                                                                "maxValue": 1
                                                            },
                                                            {
                                                                "name": "Resistance Strength",
                                                                "shortName": "CS",
                                                                "minValue": 0.35,
                                                                "averageValue": 0.4,
                                                                "maxValue": 0.45
                                                            }
                                                        ]
                                                    }
                                                ]
                                            },
                                            {
                                                "name": "Loss Magnitude",
                                                "shortName": "LM",
                                                "minValue": 4000000,
                                                "averageValue": 5000000,
                                                "maxValue": 10000000,
                                                "children": [
                                                    {
                                                        "name": "Primary Loss",
                                                        "shortName": "PL",
                                                        "minValue": 4000000,
                                                        "averageValue": 5000000,
                                                        "maxValue": 10000000
                                                    },
                                                    {
                                                        "name": "Secondary Risk",
                                                        "shortName": "SL",
                                                        "minValue": 4000000,
                                                        "averageValue": 5000000,
                                                        "maxValue": 10000000,
                                                        "children": [
                                                            {
                                                                "name": "Secondary Loss Event Frequency",
                                                                "shortName": "SLEF",
                                                                "minValue": 1,
                                                                "averageValue": 1,
                                                                "maxValue": 1
                                                            },
                                                            {
                                                                "name": "Secondary Loss Magnitude",
                                                                "shortName": "SLM",
                                                                "minValue": 4000000,
                                                                "averageValue": 5000000,
                                                                "maxValue": 10000000
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                    */
                                });
                        }
                    }
                    // console.warn('riskResponse')
                    // console.warn(riskResponse)
                    // console.warn('dataFromRiskResponse')
                    // console.warn(dataFromRiskResponse)
                    /*
                    // temp rewrite
                    dataFromRiskResponse = {
                        "riskData": {
                            "daata": "2024-02-09",
                            "name": "Ransomware",
                            "risk": {
                                "minValue": 4000000,
                                "averageValue": 9000000,
                                "maxValue": 12000000
                            },
                            "badge": {
                                "lef": {
                                    "minValue": 0.4,
                                    "averageValue": 0.8,
                                    "maxValue": 0.9
                                },
                                "lm": {
                                    "minValue": 4000000,
                                    "averageValue": 9000000,
                                    "maxValue": 12000000
                                }
                            }
                        },
                        "primaryCost": {
                            "primaryCostRange": [
                                {
                                    "title": "Minimum",
                                    "metric": 100000
                                },
                                {
                                    "title": "Most likely",
                                    "metric": 2000000
                                },
                                {
                                    "title": "Maximum",
                                    "metric": 3000000
                                }
                            ],
                            "primaryCostDetails": [
                                {
                                    "name": "Recovery cost",
                                    "minValue": 4000000,
                                    "averageValue": 5000000,
                                    "maxValue": 19000000
                                },
                                {
                                    "name": "Business interruption",
                                    "minValue": 4000000,
                                    "averageValue": 5000000,
                                    "maxValue": 19000000
                                },
                                {
                                    "name": "Cyber extortion",
                                    "minValue": 4000000,
                                    "averageValue": 5000000,
                                    "maxValue": 19000000
                                }
                            ]
                        },
                        "secondaryCost": {
                            "secondaryCostRange": [
                                {
                                    "title": "Minimum",
                                    "metric": 100000
                                },
                                {
                                    "title": "Most likely",
                                    "metric": 2000000
                                },
                                {
                                    "title": "Maximum",
                                    "metric": 3000000
                                }
                            ],
                            "secondaryCostDetails": [
                                {
                                    "name": "Post Breach Security Improvements",
                                    "description": "Security improvements undertaken voluntarily after a breach.",
                                    "minValue": 4000000,
                                    "averageValue": 9000000,
                                    "maxValue": 10000000
                                }
                            ]
                        },
                        "riskDistribution": [
                            {
                                "price": 0,
                                "simulations": 0
                            },
                            {
                                "price": 10000,
                                "simulations": 10
                            },
                            {
                                "price": 15000,
                                "simulations": 20
                            },
                            {
                                "price": 16000,
                                "simulations": 15
                            },
                            {
                                "price": 30000,
                                "simulations": 50
                            },
                            {
                                "price": 45000,
                                "simulations": 60
                            },
                            {
                                "price": 60000,
                                "simulations": 30
                            },
                            {
                                "price": 75000,
                                "simulations": 20
                            },
                            {
                                "price": 90000,
                                "simulations": 2
                            }
                        ],
                        "lossCurve": [
                            [
                                {
                                    "cost": 0,
                                    "percentage": 100
                                },
                                {
                                    "cost": 50000,
                                    "percentage": 95
                                },
                                {
                                    "cost": 100000,
                                    "percentage": 60
                                },
                                {
                                    "cost": 150000,
                                    "percentage": 10
                                },
                                {
                                    "cost": 200000,
                                    "percentage": 0
                                },
                                {
                                    "cost": 250000,
                                    "percentage": 0
                                }
                            ]
                        ],
                        "fairTree": {
                            "name": "Risk",
                            "shortName": "R",
                            "minValue": 8000000,
                            "averageValue": 14000000,
                            "maxValue": 29000000,
                            "children": [
                                {
                                    "name": "Loss Event Frequency",
                                    "shortName": "LEF",
                                    "minValue": 4000000,
                                    "averageValue": 9000000,
                                    "maxValue": 19000000,
                                    "children": [
                                        {
                                            "name": "Threat Event Frequency",
                                            "shortName": "TEF",
                                            "minValue": 0.5,
                                            "averageValue": 0.5,
                                            "maxValue": 1,
                                            "children": [
                                                {
                                                    "name": "Contact Frequency",
                                                    "shortName": "C",
                                                    "minValue": 0,
                                                    "averageValue": 0,
                                                    "maxValue": 0
                                                },
                                                {
                                                    "name": "Probability of Action",
                                                    "shortName": "A",
                                                    "minValue": 0,
                                                    "averageValue": 0,
                                                    "maxValue": 0
                                                }
                                            ]
                                        },
                                        {
                                            "name": "Vulnerability",
                                            "shortName": "V",
                                            "minValue": 0.1,
                                            "averageValue": 0.2,
                                            "maxValue": 0.5,
                                            "children": [
                                                {
                                                    "name": "Threat Capability",
                                                    "shortName": "TC",
                                                    "minValue": 0.1,
                                                    "averageValue": 0.62,
                                                    "maxValue": 1
                                                },
                                                {
                                                    "name": "Resistance Strength",
                                                    "shortName": "CS",
                                                    "minValue": 0.35,
                                                    "averageValue": 0.4,
                                                    "maxValue": 0.45
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "name": "Loss Magnitude",
                                    "shortName": "LM",
                                    "minValue": 4000000,
                                    "averageValue": 5000000,
                                    "maxValue": 10000000,
                                    "children": [
                                        {
                                            "name": "Primary Loss",
                                            "shortName": "PL",
                                            "minValue": 4000000,
                                            "averageValue": 5000000,
                                            "maxValue": 10000000
                                        },
                                        {
                                            "name": "Secondary Risk",
                                            "shortName": "SL",
                                            "minValue": 4000000,
                                            "averageValue": 5000000,
                                            "maxValue": 10000000,
                                            "children": [
                                                {
                                                    "name": "Secondary Loss Event Frequency",
                                                    "shortName": "SLEF",
                                                    "minValue": 1,
                                                    "averageValue": 1,
                                                    "maxValue": 1
                                                },
                                                {
                                                    "name": "Secondary Loss Magnitude",
                                                    "shortName": "SLM",
                                                    "minValue": 4000000,
                                                    "averageValue": 5000000,
                                                    "maxValue": 10000000
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    };
                    */
                    // Sort the dataFromRiskResponse array
                    dataFromRiskResponse.sort((a, b) => {
                        const priority = { "RANSOMWARE": 1, "DATABREACH": 2, "DDOS":3 };
                        const nameA = a.riskData?.name?.toUpperCase();
                        const nameB = b.riskData?.name?.toUpperCase();

                        if (priority[nameA] && priority[nameB]) {
                            return priority[nameA] - priority[nameB];
                        }
                        if (priority[nameA]) {
                            return -1;
                        }
                        if (priority[nameB]) {
                            return 1;
                        }
                        return nameA.localeCompare(nameB);
                    });
                    let currentBiggestVal = 0;
                    if (dataFromRiskResponse && dataFromRiskResponse[0] && dataFromRiskResponse[0].primaryCost && dataFromRiskResponse[0].secondaryCost) {
                        [
                            ...dataFromRiskResponse[0].primaryCost.primaryCostDetails,
                            ...dataFromRiskResponse[0].secondaryCost.secondaryCostDetails,
                        ].forEach(element => {
                            if (element.maxValue &&
                                element.maxValue > currentBiggestVal) {
                                currentBiggestVal = element.maxValue;
                            }
                        });
                        setBiggestMaxValue(currentBiggestVal)
                    }
                    setViews({
                        risk: dataFromRiskResponse && dataFromRiskResponse[0] ? dataFromRiskResponse : null,
                        error: riskResponse.ErrorDetail
                    });
                })
                .catch((err) => {
                    console.error('errrrrr')
                    console.error(err)
                    setViews({
                        error: 'No risk data is available right now, '
                    })
                });
        } else {
            setViews({});
        }
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar cysr_company />
            <>
                <div className={`${darkMode ? "dark " : ""}w-full`}>

                    {(views && views.risk) ? (
                        views.error
                            ? <div>{views.error}</div>
                            : (
                                // <div className="px-4 pt-4">
                                <div>
                                    {false &&
                                        <Card>
                                            <Flex className="space-x-6 justify-between">
                                                <div>
                                                    <Title>Calculation of risk</Title>
                                                    <Text>The risk below is an estimate based on your company data
                                                        {
                                                            //, you can <a href='risk-questionnaire'>prompt more data filling the questionnaire</a>
                                                        }
                                                        .</Text>
                                                </div>
                                                {showGoToQuestions ?
                                                    <div className="gap-2 flex flex-col px-6">
                                                        <Text className="text-right">
                                                            Risk customization
                                                        </Text>
                                                        <a className="ml-auto" href="risk-questionnaire">
                                                            <MDButton style={cysrConfig().button_style} size="medium">
                                                                Go to questions
                                                                <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                                                            </MDButton>
                                                        </a>
                                                    </div>
                                                    : <MDBadge color="info" size="sm" badgeContent={"Automatic calculation"} variant="text" container />

                                                }
                                            </Flex>
                                        </Card>
                                    }
                                    {false && <Text className='text-turquoise-500 dark:text-turquoise-400 mt-4 mb-1'>Risk types</Text>}
                                    <TabGroup className="mb-4 relative" onIndexChange={(index) => {
                                        // on tab change s
                                        setSelectedTab(index)
                                        let currentBiggestVal = 0;
                                        if (views.risk[index].primaryCost && views.risk[index].secondaryCost) {
                                            [
                                                ...views.risk[index].primaryCost.primaryCostDetails,
                                                ...views.risk[index].secondaryCost.secondaryCostDetails,
                                            ].forEach(element => {
                                                if (element.maxValue &&
                                                    element.maxValue > currentBiggestVal) {
                                                    currentBiggestVal = element.maxValue;
                                                }
                                            });
                                            setBiggestMaxValue(currentBiggestVal)
                                        }
                                        // on tab change e
                                    }}>
                                        <div className='pt-4' style={{
                                            position: "sticky",
                                            top: 0,
                                            left: 0,
                                            zIndex: 9,
                                        }}>
                                            <TabList className="flex-col md:flex-row w-full" variant="solid">
                                                {

                                                    /*
                                                    {
                                                        color: "red",
                                                        icon: GppMaybeSharp,
                                                        title: "Ransomware",
                                                        description: `Tootsie roll soufflé carrot cake chocolate pudding chocolate bar tootsie roll shortbread cotton candy. Tart soufflé macaroon cheesecake lollipop.`,
                                                    },
                                                    {
                                                        disabled: true,
                                                        color: "gray",
                                                        icon: SpeedSharp,
                                                        title: "DDOS",
                                                        description: `Sweet roll bear claw jelly beans powder jelly-o cookie. Donut
                        soufflé jujubes biscuit cookie.`,
                                                    },
                                                    {
                                                        disabled: true,
                                                        color: "gray",
                                                        icon: LeakRemoveSharp,
                                                        title: "Data breach",
                                                        description: `Dragée cake dragée soufflé
                        brownie shortbread pie. Tootsie roll marzipan powder muffin
                        wafer marzipan danish croissant.`,
                                                    },
                                                    */
                                                    (views.risk ? views.risk.map((x) => {
                                                        return {
                                                            color: getRatingColor(x),
                                                            icon: GppMaybeSharp,
                                                            title: x.riskData.name,
                                                            description: getDescription(x, "short"),
                                                        }
                                                    }) : []).map((tab, index) => (
                                                        <Tab key={index}

                                                            className={`max-w-none md:max-w-xs${tab.disabled ? " cursor-not-allowed" : ""
                                                                }`}
                                                            disabled={tab.disabled ? true : false}
                                                        >
                                                            <Flex className="space-x-2 justify-start">
                                                                <Icon
                                                                    className='relative'
                                                                    icon={tab.icon}
                                                                    color={tab.color}
                                                                    // variant="solid"
                                                                    variant="light"
                                                                    tooltip={`${tab.title}`}
                                                                    size="sm"
                                                                />
                                                                <Text className='text-turquoise-500 dark:text-turquoise-400'>{tab.title}</Text>
                                                            </Flex>
                                                            <Text className="whitespace-normal text-left"><span className='text-xs'>
                                                                {tab.description}
                                                            </span>
                                                            </Text>
                                                        </Tab>
                                                    ))}
                                                <div style={{
                                                    marginLeft: "auto",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                }}>
                                                    {showGoToQuestions ?
                                                        <div className="gap-2 flex flex-col px-6">
                                                            <Text className="text-right">
                                                                Risk customization
                                                            </Text>
                                                            <a className="ml-auto" href="risk-questionnaire">
                                                                <MDButton style={cysrConfig().button_style} size="medium">
                                                                    Go to questions
                                                                    <span style={cysrConfig().button_icon_right_style} dangerouslySetInnerHTML={{ __html: cysrConfig().go.icon.svg }} />
                                                                </MDButton>
                                                            </a>
                                                        </div>
                                                        : <MDBadge color="info" size="sm" badgeContent={"Automatic calculation"} variant="text" container />
                                                    }
                                                </div>
                                            </TabList>
                                        </div>
                                        <TabPanels>
                                            {views.risk.map((tab, index) => (
                                                <TabPanel key={index} className="mt-4 space-y-4">
                                                    <Card>
                                                        <Grid numItems={12} className="gap-4">
                                                            <Col numColSpan={7} numColSpanSm={9} numColSpanMd={10}>
                                                                <Metric className="mr-2 font-bold text-lg flex gap-1.5 items-center">Your company annualized risk exposure to:
                                                                    <span className="font-medium text-lg uppercase" style={{
                                                                        fontSize: "inherit",
                                                                        color: getRatingColor(views.risk[selectedTab])
                                                                    }}>
                                                                        {views.risk[selectedTab].riskData.name} <a href='#1-detail' onClick={() => detailRef.current.scrollIntoView()}>[1]</a>
                                                                    </span>
                                                                </Metric>
                                                                <div className="w-full max-w-3xl">
                                                                    <Text className="w-full max-w-3xl my-2">
                                                                        On this section, we evaluate the <strong className="text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">annualized risk</strong> for a {views.risk[selectedTab] && views.risk[selectedTab].riskData && views.risk[selectedTab].riskData.name ? `${views.risk[selectedTab].riskData.name.toUpperCase()}` : null} attack.<br />
                                                                        This estimation reflects the annualized value that should be budgeted to address this type of risk. The calculation is performed through a simulation using the FAIR (Factor Analysis of Information Risk) framework, which estimates the minimum, expected value, and maximum monetary value that should be budgeted each year to address this type of risk.<br />
                                                                        Additionally, the expected frequency for such an attack is estimated.<br /><br />
                                                                        These calculations take into account the defense capabilities of the company as measured by us, the type and size of the company, the industry sector, as well as the capabilities of the attackers.

                                                                        {
                                                                            // getDescription(views.risk[selectedTab], "long")
                                                                        }
                                                                    </Text>
                                                                    {false &&
                                                                        <Text className="w-full max-w-lg my-2">
                                                                            Risk: probability of how many times a malignant event can happen and if it happens how much we will lose in monetary terms
                                                                        </Text>
                                                                    }
                                                                    <Flex className="max-w-3xl my-4 flex-col sm:flex-row items-start">
                                                                        <div className="text-center">
                                                                            <Text>Minimum</Text>
                                                                            <Metric>{
                                                                                valueFormatter(views.risk[selectedTab].riskData.risk?.minValue)
                                                                                // valueFormatter(views.risk[selectedTab].riskData.badge?.lm?.minValue)
                                                                            }</Metric>
                                                                        </div>
                                                                        <div className="text-center">
                                                                            <Text className='text-turquoise-500 dark:text-turquoise-400'>Expected</Text>
                                                                            <Metric><span style={{ color: getRatingColor(views.risk[selectedTab]) }}>{
                                                                                valueFormatter(views.risk[selectedTab].riskData.risk?.averageValue)
                                                                                // valueFormatter(views.risk[selectedTab].riskData.badge?.lm?.averageValue)
                                                                            }</span></Metric>
                                                                        </div>
                                                                        <div className="text-center">
                                                                            <Text>Maximum</Text>
                                                                            <Metric>{
                                                                                valueFormatter(views.risk[selectedTab].riskData.risk?.maxValue)
                                                                                // valueFormatter(views.risk[selectedTab].riskData.badge?.lm?.maxValue)
                                                                            }</Metric>
                                                                        </div>
                                                                    </Flex>
                                                                </div>
                                                                {false &&
                                                                    <Text className='text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis'>
                                                                        Scenario for {views.risk[selectedTab].riskData.name} <a href='#1-detail' onClick={() => detailRef.current.scrollIntoView()}>[1]</a>
                                                                    </Text>
                                                                }
                                                            </Col>
                                                            <Col numColSpan={5} numColSpanSm={3} numColSpanMd={2}>
                                                                <Flex className="ml-auto relative max-w-[124px] justify-end" style={
                                                                    {
                                                                        color: getRatingColor(views.risk[selectedTab])
                                                                    }
                                                                }>
                                                                    <svg
                                                                        className="max-w-[124px] w-full h-auto inline-flex" width="124" height="196" viewBox="0 0 124 196" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0 0V195.96H123.34V0H0ZM117.52 190.14H5.82V5.82H39.57V26.17H46.16V5.82H52.35V26.17H58.94V5.82H65.15L65.12 33.69H71.71L71.68 5.82H77.89V26.17H84.48V5.82H117.52V190.14Z" fill="currentColor" />
                                                                        {
                                                                            // <path d="M58.7105 117.64C58.6205 117.37 58.5205 117.11 58.4305 116.85C58.3305 116.59 58.2405 116.33 58.1505 116.05H55.3105C55.2205 116.32 55.1305 116.59 55.0305 116.85C54.9405 117.11 54.8405 117.37 54.7505 117.63H53.4805C53.7305 116.91 53.9705 116.25 54.2005 115.64C54.4305 115.03 54.6505 114.46 54.8605 113.91C55.0805 113.37 55.2905 112.85 55.5005 112.36C55.7105 111.87 55.9305 111.38 56.1605 110.91H57.3305C57.5605 111.39 57.7805 111.87 57.9905 112.36C58.2005 112.85 58.4205 113.37 58.6305 113.91C58.8505 114.45 59.0705 115.03 59.3005 115.64C59.5305 116.25 59.7705 116.91 60.0205 117.63H58.7005L58.7105 117.64ZM56.7305 112.22C56.5805 112.56 56.4105 112.98 56.2205 113.47C56.0305 113.96 55.8305 114.5 55.6205 115.07H57.8305C57.6205 114.5 57.4205 113.96 57.2305 113.46C57.0405 112.96 56.8705 112.55 56.7305 112.22Z" fill="currentColor" />
                                                                            // <path d="M65.1201 116.58V117.64H60.8701V110.92H62.0901V116.58H65.1201Z" fill="currentColor" />
                                                                            // <path d="M66.0498 117.64V110.92H70.3698V111.96H67.2798V113.62H70.0398V114.64H67.2798V116.61H70.6098V117.65H66.0598L66.0498 117.64Z" fill="currentColor" />
                                                                        }
                                                                    </svg>
                                                                    <span style={
                                                                        {
                                                                            left: 0,
                                                                            bottom: 132,
                                                                            color: "inherit",
                                                                            right: 0,
                                                                            fontSize: 14,
                                                                            fontWeight: 500,
                                                                            textAlign: "center"
                                                                        }
                                                                    } className='absolute'>
                                                                        {views.risk[selectedTab].riskData.name}
                                                                    </span>
                                                                    <span style={
                                                                        {
                                                                            left: 0,
                                                                            bottom: 84,
                                                                            color: "inherit",
                                                                            right: 0,
                                                                            fontSize: 32,
                                                                            fontWeight: 500,
                                                                            textAlign: "center"
                                                                        }
                                                                    } className='absolute'>
                                                                        {
                                                                            // using lm
                                                                            // valueFormatter(views.risk[selectedTab].riskData.badge.lm.averageValue, 1)
                                                                        }
                                                                        {
                                                                            // valueFormatter(views.risk[selectedTab].riskData.risk.averageValue, 2)
                                                                        }
                                                                        {
                                                                            valueFormatter(views.risk[selectedTab].riskData.risk.averageValue, 1).length > badgeNumberMaxLengthForDecimal ?
                                                                                valueFormatter(views.risk[selectedTab].riskData.risk.averageValue, 0)
                                                                                : valueFormatter(views.risk[selectedTab].riskData.risk.averageValue, 1)
                                                                        }
                                                                    </span>
                                                                    <span style={
                                                                        {
                                                                            bottom: 74,
                                                                            color: "inherit",
                                                                            left: 12,
                                                                            right: 68,
                                                                            fontSize: 12,
                                                                            fontWeight: 500,
                                                                            textAlign: "center",
                                                                        }
                                                                    } className='absolute'>
                                                                        min
                                                                    </span>
                                                                    <span style={
                                                                        {
                                                                            bottom: 56,
                                                                            color: "inherit",
                                                                            left: 12,
                                                                            right: 68,
                                                                            fontSize: 14,
                                                                            fontWeight: 500,
                                                                            textAlign: "center",
                                                                        }
                                                                    } className='absolute'>
                                                                        {
                                                                            // using lm
                                                                            // valueFormatter(views.risk[selectedTab].riskData.badge.lm.maxValue < views.risk[selectedTab].riskData.badge.lm.minValue ? views.risk[selectedTab].riskData.badge.lm.maxValue : views.risk[selectedTab].riskData.badge.lm.minValue, 1)
                                                                            // valueFormatter(views.risk[selectedTab].riskData.risk.maxValue < views.risk[selectedTab].riskData.risk.minValue ? views.risk[selectedTab].riskData.risk.maxValue : views.risk[selectedTab].riskData.risk.minValue, 2)
                                                                        }
                                                                        {
                                                                            valueFormatter(views.risk[selectedTab].riskData.risk.maxValue < views.risk[selectedTab].riskData.risk.minValue ?
                                                                                views.risk[selectedTab].riskData.risk.maxValue
                                                                                : views.risk[selectedTab].riskData.risk.minValue, 1).length > badgeNumberMaxLengthForDecimal ?
                                                                                valueFormatter(views.risk[selectedTab].riskData.risk.maxValue < views.risk[selectedTab].riskData.risk.minValue ?
                                                                                    views.risk[selectedTab].riskData.risk.maxValue
                                                                                    : views.risk[selectedTab].riskData.risk.minValue, 0)
                                                                                :
                                                                                valueFormatter(views.risk[selectedTab].riskData.risk.maxValue < views.risk[selectedTab].riskData.risk.minValue ?
                                                                                    views.risk[selectedTab].riskData.risk.maxValue
                                                                                    : views.risk[selectedTab].riskData.risk.minValue, 1)
                                                                        }
                                                                    </span>
                                                                    <span style={
                                                                        {
                                                                            bottom: 74,
                                                                            color: "inherit",
                                                                            left: 52,
                                                                            right: 52,
                                                                            fontSize: 8,
                                                                            fontWeight: 400,
                                                                            textAlign: "center",
                                                                        }
                                                                    } className='absolute'>
                                                                        {
                                                                            // to
                                                                        }
                                                                    </span>
                                                                    <span style={
                                                                        {
                                                                            bottom: 74,
                                                                            color: "inherit",
                                                                            left: 68,
                                                                            right: 12,
                                                                            fontSize: 12,
                                                                            fontWeight: 500,
                                                                            textAlign: "center",
                                                                        }
                                                                    } className='absolute'>
                                                                        max
                                                                    </span>
                                                                    <span style={
                                                                        {
                                                                            bottom: 56,
                                                                            color: "inherit",
                                                                            left: 68,
                                                                            right: 12,
                                                                            fontSize: 14,
                                                                            fontWeight: 500,
                                                                            textAlign: "center",
                                                                        }
                                                                    } className='absolute'>
                                                                        {
                                                                            // using lm
                                                                            // valueFormatter(views.risk[selectedTab].riskData.badge.lm.minValue > views.risk[selectedTab].riskData.badge.lm.maxValue ? views.risk[selectedTab].riskData.badge.lm.minValue : views.risk[selectedTab].riskData.badge.lm.maxValue, 1)}
                                                                            // valueFormatter(views.risk[selectedTab].riskData.risk.minValue > views.risk[selectedTab].riskData.risk.maxValue ? views.risk[selectedTab].riskData.risk.minValue : views.risk[selectedTab].riskData.risk.maxValue, 2)
                                                                        }
                                                                        {
                                                                            valueFormatter(views.risk[selectedTab].riskData.risk.minValue > views.risk[selectedTab].riskData.risk.maxValue ? views.risk[selectedTab].riskData.risk.minValue : views.risk[selectedTab].riskData.risk.maxValue, 1).length > badgeNumberMaxLengthForDecimal ?
                                                                                valueFormatter(views.risk[selectedTab].riskData.risk.minValue > views.risk[selectedTab].riskData.risk.maxValue ? views.risk[selectedTab].riskData.risk.minValue : views.risk[selectedTab].riskData.risk.maxValue, 0) :
                                                                                valueFormatter(views.risk[selectedTab].riskData.risk.minValue > views.risk[selectedTab].riskData.risk.maxValue ? views.risk[selectedTab].riskData.risk.minValue : views.risk[selectedTab].riskData.risk.maxValue, 1)
                                                                        }
                                                                    </span>
                                                                    {
                                                                        /*
                                                                    }
                                                                    <span style={
                                                                        {
                                                                            bottom: 84,
                                                                            color: "inherit",
                                                                            left: 0,
                                                                            right: 0,
                                                                            fontSize: 12,
                                                                            fontWeight: 500,
                                                                            textAlign: "center"
                                                                        }
                                                                    } className='absolute'>
                                                                        {valueFormatter(views.risk[selectedTab].riskData.badge.lm.maxValue)}
                                                                    </span>
                                                                    <span style={
                                                                        {
                                                                            bottom: 34,
                                                                            color: "inherit",
                                                                            right: 76,
                                                                            fontSize: 15,
                                                                            textAlign: "center"
                                                                        }
                                                                    } className='absolute'>
                                                                        {100 * (views.risk[selectedTab].riskData.badge.lef.minValue) + "%"}
                                                                    </span>
                                                                    {
                                                                        */
                                                                        /*
                                                                        // percentage
                                                                     }
                                                                     <span style={
                                                                         {
                                                                             left: 0,
                                                                             bottom: 32,
                                                                             color: "inherit",
                                                                             right: 0,
                                                                             fontSize: 24,
                                                                             fontWeight: 500,
                                                                             textAlign: "center"
                                                                         }
                                                                     } className='absolute'>
                                                                         {(100 * (views.risk[selectedTab].riskData.badge.lef.averageValue)).toFixed(2) + "%"}
                                                                     </span>
                                                                     {
                                                                         */
                                                                        /*
                                                                    }
                                                                    <span style={
                                                                        {
                                                                            bottom: 34,
                                                                            color: "inherit",
                                                                            right: 16,
                                                                            fontSize: 15,
                                                                            textAlign: "center"
                                                                        }
                                                                    } className='absolute'>
                                                                        {100 * (views.risk[selectedTab].riskData.badge.lef.maxValue) + "%"}
                                                                    </span>
                                                                    {
                                                                        */
                                                                    }
                                                                    <span style={
                                                                        {
                                                                            left: 0,
                                                                            bottom: 32,
                                                                            color: "inherit",
                                                                            right: 0,
                                                                            fontSize: 15,
                                                                            fontWeight: 500,
                                                                            textAlign: "center"
                                                                        }
                                                                    } className='absolute'>
                                                                        {
                                                                            views.risk[selectedTab].riskData.badge.lef.averageValue < 0.1 ? "< 1 event" : `${(Math.ceil(1 / views.risk[selectedTab].riskData.badge.lef.averageValue) * views.risk[selectedTab].riskData.badge.lef.averageValue).toFixed(2)} events`}
                                                                    </span>
                                                                    <span style={
                                                                        {
                                                                            left: 0,
                                                                            bottom: 14,
                                                                            color: "inherit",
                                                                            right: 0,
                                                                            fontSize: 16,
                                                                            fontWeight: 500,
                                                                            textAlign: "center"
                                                                        }
                                                                    } className='absolute'>
                                                                        {
                                                                            // views.risk[selectedTab].riskData.badge.lef.averageValue < 0.1 ? "Less than 1 event" : `${(Math.ceil(1 / views.risk[selectedTab].riskData.badge.lef.averageValue) * views.risk[selectedTab].riskData.badge.lef.averageValue).toFixed(2)} events in ${Math.ceil(1 / views.risk[selectedTab].riskData.badge.lef.averageValue)} years`}
                                                                            views.risk[selectedTab].riskData.badge.lef.averageValue < 0.1 ? "in 10 years" : `in ${Math.ceil(1 / views.risk[selectedTab].riskData.badge.lef.averageValue)} years`}
                                                                    </span>
                                                                    {
                                                                        /*
                                                                    }
                                                                    {views.risk[selectedTab].riskData.badge.lef.averageValue
                                                                    }{views.risk[selectedTab].riskData.badge.lef.maxValue
            
                                                                    }{views.risk[selectedTab].riskData.badge.lm.minValue
                                                                    }{views.risk[selectedTab].riskData.badge.lm.averageValue
                                                                    }{views.risk[selectedTab].riskData.badge.lm.maxValue
                                                                    }
                                                                    {
                                                                        */
                                                                    }
                                                                </Flex>
                                                            </Col>
                                                        </Grid>
                                                    </Card>
                                                    <Card>
                                                        <Metric className="mr-2 font-bold text-lg flex gap-1.5 items-center">Loss Magnitude and Loss Event Frequency</Metric>
                                                        {
                                                            // <Text className="w-full max-w-lg my-2">
                                                        }
                                                        <div className="grid lg:grid-cols-2 items-start gap-6">
                                                            <div>
                                                                <Text className="w-full max-w-3xl my-2">
                                                                    The <strong className="text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">Loss Magnitude</strong> estimates the total expenses expected in the event of a <strong className="text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">single {views.risk[selectedTab] && views.risk[selectedTab].riskData && views.risk[selectedTab].riskData.name ? `${views.risk[selectedTab].riskData.name.toUpperCase()}` : null}-type incident</strong>, calculated through simulations considering both primary and secondary costs that have been previously entered or computed.<br />
                                                                    The Loss Magnitude does not directly sum up the estimated costs of its components; instead, it is derived from the PERT distributions based on the minimum, most likely and maximum values of its components. <a href='#2-methodology' onClick={() => methodologyRef.current.scrollIntoView()}>[2]</a>
                                                                </Text>
                                                                {false && <>
                                                                    <Text className="w-full max-w-lg my-2">
                                                                        We assess the annualized risk for a {views.risk[selectedTab] && views.risk[selectedTab].riskData && views.risk[selectedTab].riskData.name ? `${views.risk[selectedTab].riskData.name.toUpperCase()}` : null} attack.
                                                                        This estimation reflects the annualized value that should be budgeted to address this type of risk.
                                                                        The calculation is performed through a simulation that estimates the minimum, median, and maximum monetary value that should be budgeted each year to address this type of risk.
                                                                    </Text>
                                                                    <Text className="w-full max-w-lg my-2">
                                                                        Additionally, the expected frequency for such an attack is estimated.
                                                                        These calculations take into account the defense capabilities of the company, the type and size of the company, the industry sector, as well as the capabilities of the attackers.
                                                                    </Text></>
                                                                }
                                                                {
                                                                    /*
        
                                {
                                    "name": "Loss Magnitude",
                                    "maxValue": 564877.2952833588,
                                    "minValue": 9446.237363137961,
                                    "shortName": "LM",
                                    "averageValue": 152365.98268492962
                                }
                                                                    */
                                                                    // <Flex className="max-w-lg my-4 flex-col sm:flex-row items-start">
                                                                }
                                                                <Flex className="max-w-3xl my-4 flex-col sm:flex-row items-start">
                                                                    <div className="text-center">
                                                                        {false && <Text>Loss Magnitude</Text>}
                                                                        <Text>Minimum</Text>
                                                                        <Metric>{
                                                                            valueFormatter(views.risk[selectedTab].fairTree?.children?.find((x) => x.name === "Loss Magnitude").minValue)
                                                                        }</Metric>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        {false && <Text className='text-turquoise-500 dark:text-turquoise-400'>Average expected cost</Text>}
                                                                        <Text className='text-turquoise-500 dark:text-turquoise-400'>Expected</Text>
                                                                        <Metric><span style={{ color: getRatingColor(views.risk[selectedTab]) }}>{
                                                                            valueFormatter(views.risk[selectedTab].fairTree?.children?.find((x) => x.name === "Loss Magnitude").averageValue)
                                                                            // valueFormatter(views.risk[selectedTab].riskData.badge?.lm?.averageValue)
                                                                        }</span></Metric>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        {false && <Text>Max expected cost</Text>}
                                                                        <Text>Maximum</Text>
                                                                        <Metric>{
                                                                            valueFormatter(views.risk[selectedTab].fairTree?.children?.find((x) => x.name === "Loss Magnitude").maxValue)
                                                                            // valueFormatter(views.risk[selectedTab].riskData.badge?.lm?.maxValue)
                                                                        }</Metric>
                                                                    </div>
                                                                </Flex>
                                                            </div>
                                                            <div className="flex flex-col justify-between h-full">
                                                                <Text className="w-full max-w-3xl my-2">
                                                                    The <strong className="text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">Loss Event Frequency</strong> estimates the <strong className="text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">frequency of occurrences of events that result in losses</strong> for the company.<br />
                                                                    The calculation considers the frequency of attempted attacks on the company, the skill level of threat actors and the resistance of the company's Attack Perimeter against such scenarios. <a href='#2-methodology' onClick={() => methodologyRef.current.scrollIntoView()}>[2]</a>
                                                                </Text>

                                                                <Flex className="max-w-3xl my-4 flex-col sm:flex-row items-start justify-center">
                                                                    <div className="text-center">
                                                                        <Text className='text-turquoise-500 dark:text-turquoise-400'>Expected</Text>
                                                                        <Metric>
                                                                            <span style={{ color: getRatingColor(views.risk[selectedTab]) }}>
                                                                                {
                                                                                    views.risk[selectedTab].riskData.badge.lef.averageValue < 0.1 ? "< 1 event" : `${(Math.ceil(1 / views.risk[selectedTab].riskData.badge.lef.averageValue) * views.risk[selectedTab].riskData.badge.lef.averageValue).toFixed(2)} events`
                                                                                }{" "}
                                                                                {
                                                                                    views.risk[selectedTab].riskData.badge.lef.averageValue < 0.1 ? "in 10 years" : `in ${Math.ceil(1 / views.risk[selectedTab].riskData.badge.lef.averageValue)} years`
                                                                                }
                                                                            </span>
                                                                        </Metric>
                                                                    </div>
                                                                </Flex>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                    <Card>
                                                        <Title>
                                                            {
                                                                // Single event loss expectancy
                                                            }
                                                            Estimated cost breakdown</Title>
                                                        <Text className="max-w-3xl mb-4">
                                                            {
                                                                // This section will represent all types of costs, and probable values{views.risk[selectedTab] && views.risk[selectedTab].riskData && views.risk[selectedTab].riskData.name ? ` , linked to ${views.risk[selectedTab].riskData.name.toLowerCase()} risk` : null}.
                                                            }
                                                            This section provides a breakdown of the types of costs associated with {views.risk[selectedTab] && views.risk[selectedTab].riskData && views.risk[selectedTab].riskData.name ? `${views.risk[selectedTab].riskData.name.toUpperCase()}` : null} risk. <br />Each one is represented as minimum, most likely, and maximum values of a PERT distribution.
                                                        </Text>
                                                        {
                                                            false && <>
                                                                <Grid numItemsMd={3} className="gap-6">
                                                                    <List>
                                                                        <ListItem className="flex-col items-start py-0">
                                                                            <Text className='text-turquoise-500 dark:text-turquoise-400'>Primary Cost:</Text>
                                                                            <List>
                                                                                <ListItem className="inline-block text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">
                                                                                    <Bold>Recovery Cost</Bold>: Expenses for responding
                                                                                    to and recovering from network security events.
                                                                                </ListItem>
                                                                                <ListItem className="inline-block text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">
                                                                                    <Bold>Business Interruption</Bold>: direct costs caused
                                                                                    by business interruption due to cybersecurity incidents.
                                                                                </ListItem>
                                                                                <ListItem className="inline-block text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">
                                                                                    <Bold>Ransom Payment</Bold>: Includes direct costs
                                                                                    related to ransomware attacks, such as ransom payments.
                                                                                </ListItem>
                                                                            </List>
                                                                        </ListItem>
                                                                    </List>
                                                                    <Col
                                                                        numColSpanMd={2}
                                                                        className="text-center flex flex-col gap-12"
                                                                    >
                                                                        {[
                                                                            {
                                                                                label: 'Primary cost (Quantitative)',
                                                                                costs: views.risk[selectedTab].primaryCost?.primaryCostRange ? views.risk[selectedTab].primaryCost?.primaryCostRange : []
                                                                            }
                                                                        ].map((x) => (
                                                                            <div>
                                                                                <Text className='text-turquoise-500 dark:text-turquoise-400 mb-4'>
                                                                                    {x.label}
                                                                                </Text>
                                                                                <Grid numItemsLg={3} className="gap-6">
                                                                                    {x.costs.map((item, i) => (
                                                                                        <section className="text-center" key={item.title}>
                                                                                            {i === 1
                                                                                                ? <Metric>{valueFormatter(item.metric)}</Metric>
                                                                                                : <Metric style={{ color: "#6C7A7D" }}>{valueFormatter(item.metric)}</Metric>
                                                                                            }

                                                                                            {/*i === 1
                                                                            ? <Text color='red'>{item.title}</Text>
                                                                            :*/ <Text color={i === 1 && "#5C959F"}>{item.title}</Text>
                                                                                            }
                                                                                            {false && (
                                                                                                <Flex className="mt-4">
                                                                                                    <Text className="truncate">{`${item.value}% (${item.metric})`}</Text>
                                                                                                    <Text>{item.target}</Text>
                                                                                                </Flex>
                                                                                            )}
                                                                                            {false && (
                                                                                                <ProgressBar
                                                                                                    color="red"
                                                                                                    value={item.value}
                                                                                                    className="mt-2"
                                                                                                />
                                                                                            )}
                                                                                        </section>
                                                                                    ))}
                                                                                </Grid>
                                                                            </div>
                                                                        ))}
                                                                    </Col>
                                                                </Grid>
                                                                <Grid numItemsMd={3} className="gap-6 mt-8">
                                                                    <List>
                                                                        <ListItem className="flex-col items-start py-0">
                                                                            <Text className='text-turquoise-500 dark:text-turquoise-400'>Secondary Cost:</Text>
                                                                            <List>
                                                                                <ListItem className="inline-block text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">
                                                                                    <Bold>Post Breach Security Improvements</Bold>: Security
                                                                                    improvements undertaken volountarily after a breach.
                                                                                </ListItem>
                                                                            </List>
                                                                        </ListItem>
                                                                    </List>

                                                                    <Col
                                                                        numColSpanMd={2}
                                                                        className="text-center flex flex-col gap-12"
                                                                    >
                                                                        {[
                                                                            {
                                                                                label: 'Secondary cost (Qualitative)',
                                                                                costs: views.risk[selectedTab].secondaryCost?.secondaryCostRange ? views.risk[selectedTab].secondaryCost?.secondaryCostRange : []
                                                                            }
                                                                        ].map((x) => (
                                                                            <div>
                                                                                <Text className='text-turquoise-500 dark:text-turquoise-400 mb-4'>
                                                                                    {x.label}
                                                                                </Text>
                                                                                <Grid numItemsLg={3} className="gap-6">
                                                                                    {x.costs.map((item, i) => (
                                                                                        <section className="text-center" key={item.title}>
                                                                                            {i === 1
                                                                                                ? <Metric>{valueFormatter(item.metric)}</Metric>
                                                                                                : <Metric style={{ color: "#6C7A7D" }}>{valueFormatter(item.metric)}</Metric>
                                                                                            }
                                                                                            {/*i === 1
                                                                            ? <Text color='red'>{item.title}</Text>
                                                                            :*/ <Text color={i === 1 && "#5C959F"}>{item.title}</Text>
                                                                                            }
                                                                                            {false && (
                                                                                                <Flex className="mt-4">
                                                                                                    <Text className="truncate">{`${item.value}% (${item.metric})`}</Text>
                                                                                                    <Text>{item.target}</Text>
                                                                                                </Flex>
                                                                                            )}
                                                                                            {false && (
                                                                                                <ProgressBar
                                                                                                    color="red"
                                                                                                    value={item.value}
                                                                                                    className="mt-2"
                                                                                                />
                                                                                            )}
                                                                                        </section>
                                                                                    ))}
                                                                                </Grid>
                                                                            </div>
                                                                        ))}
                                                                    </Col>
                                                                </Grid></>
                                                        }
                                                        {/*
            }
          </Card>
          <Card className="w-full">
          {
            */}
                                                        {
                                                            // <div className="space-y-8 mt-16 divide-y divide-tremor-border dark:divide-dark-tremor-border">
                                                        }
                                                        <div className="space-y-8 divide-y divide-tremor-border dark:divide-dark-tremor-border">

                                                            {/*[
                                                    {
                                                        title: "Information privacy",
                                                        values: {
                                                            labelMinValue: "€ 0.5M",
                                                            labelValue: "€ 2M",
                                                            labelMaxValue: "€ 11M",
                                                            labelPercentage: `${getPercentageRange(2, biggestMaxValue)
                                                                .toFixed(2)
                                                                .toString()}%`,
                                                            minValue: getPercentageRange(0.5, biggestMaxValue),
                                                            value: getPercentageRange(2, biggestMaxValue),
                                                            maxValue: getPercentageRange(11, biggestMaxValue),
                                                        },
                                                    },
                                                    {
                                                        title: "Business interruption",
                                                    },
                                                    {
                                                        title: "Cyber extortion",
                                                        label: "No ransom payment reported yet",
                                                    },
                                                    {
                                                        title: "Network security",
                                                        values: {
                                                            labelMinValue: "€ 0.04M",
                                                            labelValue: "€ 0.2M",
                                                            labelMaxValue: "€ 0.4M",
                                                            // labelPercentage: `${((value * 100) / maxValue)
                                                            labelPercentage: `${getPercentageRange(
                                                                0.2,
                                                                biggestMaxValue
                                                            )
                                                                .toFixed(2)
                                                                .toString()}%`,
                                                            // minValue: 0.04,
                                                            minValue: getPercentageRange(0.04, biggestMaxValue),
                                                            // value: 0.2,
                                                            value: getPercentageRange(0.2, biggestMaxValue),
                                                            // maxValue: 0.4,
                                                            maxValue: getPercentageRange(0.4, biggestMaxValue),
                                                        },
                                                    },
                                                    {
                                                        title: "Reputational damage",
                                                        values: {
                                                            labelMinValue: "€ 54M",
                                                            labelValue: "€ 90M",
                                                            labelMaxValue: "€ 179M",
                                                            labelPercentage: `${getPercentageRange(
                                                                90,
                                                                biggestMaxValue
                                                            )
                                                                .toFixed(2)
                                                                .toString()}%`,
                                                            minValue: getPercentageRange(54, biggestMaxValue),
                                                            value: getPercentageRange(90, biggestMaxValue),
                                                            maxValue: getPercentageRange(179, biggestMaxValue),
                                                        },
                                                    },
                                                ]*/
                                                            }
                                                            {views.risk[selectedTab].primaryCost && views.risk[selectedTab].secondaryCost ? [
                                                                ...views.risk[selectedTab].primaryCost.primaryCostDetails,
                                                                ...views.risk[selectedTab].secondaryCost.secondaryCostDetails
                                                            ].map((cost) => (
                                                                <Grid numItems={12}>
                                                                    <Col numColSpan={4} numColSpanLg={3}>
                                                                        <Text className="py-4 text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">
                                                                            {cost.name}
                                                                        </Text>
                                                                        <Flex className='justify-start' style={{ color: "#D1D1D1" }}>
                                                                            {cost.averageValue ? <Text>
                                                                                <span className="relative">
                                                                                    <Flex className='absolute left-0 right-0 justify-center' style={{
                                                                                        bottom: 14
                                                                                    }}>
                                                                                        {cost.averageValue ? <Text className="text-[10px]">
                                                                                            Min
                                                                                        </Text> : null}
                                                                                    </Flex>
                                                                                    {valueFormatter(cost.minValue)}
                                                                                </span>
                                                                                {" - "}
                                                                                <Metric className='relative inline font-medium text-tremor-default'>
                                                                                    <Flex className='absolute left-0 right-0 justify-center' style={{
                                                                                        bottom: 14
                                                                                    }}>
                                                                                        {cost.averageValue ? <Text className="text-[10px] whitespace-nowrap">
                                                                                            Most Likely
                                                                                        </Text> : null}
                                                                                    </Flex>
                                                                                    {`${valueFormatter(cost.averageValue)}`}</Metric>
                                                                                {" - "}
                                                                                <span className="relative">
                                                                                    <Flex className='absolute left-0 right-0 justify-center' style={{
                                                                                        bottom: 14
                                                                                    }}>
                                                                                        {cost.averageValue ? <Text className="text-[10px]">
                                                                                            Max
                                                                                        </Text> : null}
                                                                                    </Flex>
                                                                                    {valueFormatter(cost.maxValue)}
                                                                                </span>
                                                                            </Text> : null}
                                                                        </Flex>
                                                                    </Col>
                                                                    <Col numColSpan={8} numColSpanLg={9}>
                                                                        {cost.averageValue ? (
                                                                            <div className={
                                                                                ""
                                                                                // cost.description && cost.description.length > 0 ? "" : "pt-4"
                                                                            }>
                                                                                {
                                                                                    cost.description && cost.description.length > 0
                                                                                        ? (
                                                                                            <Text className="pt-4 text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">
                                                                                                {cost.description}
                                                                                            </Text>
                                                                                        )
                                                                                        :
                                                                                        <Text className="pt-4 text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">
                                                                                            {cost.name.toLowerCase() === "recovery cost"
                                                                                                ? "Expenses for decrypting the data and restoring all systems to full operational capacity." // "Expenses for responding to and recovering from network security events."
                                                                                                : cost.name.toLowerCase() === "business interruption"
                                                                                                    ? // "Direct costs caused by business interruption due to cybersecurity incidents."
                                                                                                    <>Missed revenue due to ransomware, with impacts ranging from low (1 day), most likely (8 days), to high (73 days). <a href='#2-methodology' onClick={() => methodologyRef.current.scrollIntoView()}>[2]</a></>
                                                                                                    : cost.name.toLowerCase() === "ransom payment"
                                                                                                        ? "Ransom payment requested by the attacker." // "Includes direct costs related to ransomware attacks, such as ransom payments."
                                                                                                        : cost.name.toLowerCase() === "post breach security improvements"
                                                                                                            ? "The voluntary increase in the company's annual cybersecurity budget made to enhance its defenses following a successful ransomware incident."
                                                                                                            : cost.name.toLowerCase() === "investigation"
                                                                                                                ? "The detection requires advanced cybersecurity tools and expert personnel."
                                                                                                                : cost.name.toLowerCase() === "remediation"
                                                                                                                    ? "This can involve system repairs, data recovery, and strengthening cybersecurity measures to prevent future breaches"
                                                                                                                    : cost.name.toLowerCase() === "crisis management"
                                                                                                                        ? "Managing communications both internally and externally to investors, customers, regulators, etc."
                                                                                                                        : cost.name.toLowerCase() === "reputational damage"
                                                                                                                            ? "The damage to a company’s brand can be long-lasting. The impact can reach well beyond loss of existing and prospecting customers."
                                                                                                                            : cost.name.toLowerCase() === "notification"
                                                                                                                                ? "We are referring to the expenses incurred by an organization to inform the relevant people and authorities about the data breach."
                                                                                                                                : cost.name.toLowerCase() === "fines and penalties"
                                                                                                                                    ? "If the breached data was protected under laws such as GDPR or HIPAA, then the organization may face substantial penalties."
                                                                                                                                    : cost.name.toLowerCase() === "crisis management"
                                                                                                                                        ? "Managing communications both internally and externally (to investors, customers, regulators, etc.) requires careful planning and execution to minimize damage to reputation and trust. It often requires legal advisors and specialist consultants."
                                                                                                                                        : null
                                                                                            }
                                                                                        </Text>

                                                                                }
                                                                                <Flex className='mt-2 gap-2'>
                                                                                    <Text className='whitespace-nowrap'>
                                                                                        {valueFormatter(0)}
                                                                                    </Text>
                                                                                    <div className='relative w-full'>
                                                                                        <div className='absolute' style={
                                                                                            {
                                                                                                // top: -44,
                                                                                                bottom: -32,
                                                                                                // left: `${getPercentageRange(cost.values.value, biggestMaxValue)}%`
                                                                                                // left: `${getPercentageRange(cost.averageValue, cost.maxValue)}`,
                                                                                                left: `${getPercentageRange(cost.averageValue, biggestMaxValue)}%`,
                                                                                                transform: "translateX(-50%)"
                                                                                            }
                                                                                        }>
                                                                                            {
                                                                                                // <Text color='red'>{`${cost.values.labelValue} (${cost.values.labelPercentage})`}</Text>
                                                                                            }
                                                                                            <Metric style={{
                                                                                                fontSize: 14
                                                                                            }} className='font-medium'>{`${valueFormatter(cost.averageValue)}`}</Metric>
                                                                                        </div>
                                                                                        <MarkerBar
                                                                                            className='cadetBlueMarkerBar'
                                                                                            minValue={getPercentageRange(cost.minValue, biggestMaxValue)}
                                                                                            value={getPercentageRange(cost.averageValue, biggestMaxValue)}
                                                                                            maxValue={getPercentageRange(cost.maxValue, biggestMaxValue)}
                                                                                            rangeTooltip={`${valueFormatter(cost.minValue)} - ${valueFormatter(cost.maxValue)}`}
                                                                                            color={darkMode ? "white" : "teal"}
                                                                                        />
                                                                                    </div>
                                                                                    <Text className='whitespace-nowrap'>{valueFormatter(biggestMaxValue)}</Text>
                                                                                </Flex>
                                                                            </div>
                                                                        ) : cost.description ? (
                                                                            <Text className="pt-4 text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">
                                                                                {cost.description}
                                                                            </Text>
                                                                        ) : (
                                                                            <Text className='pt-4'>No data available</Text>
                                                                        )}
                                                                    </Col>
                                                                </Grid>
                                                            )) : null}
                                                        </div>
                                                    </Card>

                                                    <Card>
                                                        <Title>Annualized risk expectancy</Title>
                                                        <Text className="max-w-lg mb-4">

                                                        </Text>
                                                        <Grid numItemsMd={2} className="gap-6">
                                                            <Col>
                                                                <div>
                                                                    <Text className="pt-4 text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">Risk distribution</Text>
                                                                    <div className="relative">
                                                                        {
                                                                            /*
                                                                            <AreaChart
                                                                                className="mt-4 h-80 absolute left-0 bottom-0"
                                                                                data={riskData}
                                                                                categories={["simulations_step"]}
                                                                                // curveType="step"
                                                                                index="price"
                                                                                colors={["cyan"]}
                                                                                yAxisWidth={60}
                                                                                valueFormatter={() => ""}
                                                                                // showXAxis={false}
                                                                                // showYAxis={false}
                                                                                intervalType="preserveStartEnd"
                                                                                showGridLines={false}
                                                                                showLegend={false}
                                                                                showTooltip={false}
                
                                                                                // tickGap={0}
                
                                                                                // stack={true}
                                                                                // relative={true}
                                                                                // autoMinValue={true}
                                                                                curveType="step"
                                                                            />
                                                                            */
                                                                        }
                                                                        <AreaChart
                                                                            className="mt-4 h-80"
                                                                            style={{
                                                                                transform: "translateX(-18px)"
                                                                            }}
                                                                            data={
                                                                                // views.risk[selectedTab].riskDistribution

                                                                                [
                                                                                    ...(views.risk[selectedTab].riskDistribution && views.risk[selectedTab].riskDistribution[0] && Array.isArray(views.risk[selectedTab].riskDistribution[0])
                                                                                        ? views.risk[selectedTab].riskDistribution[0]
                                                                                        : views.risk[selectedTab].riskDistribution ? views.risk[selectedTab].riskDistribution : []
                                                                                    ).map((x) => ({
                                                                                        price: valueFormatter(x.price),
                                                                                        simulations: x.simulations
                                                                                    }))
                                                                                ]
                                                                            }
                                                                            categories={["simulations"]}
                                                                            curveType={"monotone"}
                                                                            index="price"
                                                                            showLegend={false}
                                                                            colors={["teal"]}
                                                                            yAxisWidth={60}
                                                                            valueFormatter={(x) => x.toFixed(0) + "%"}
                                                                            intervalType="preserveStartEnd"
                                                                            showGradient={false}
                                                                        // showGridLines={false}
                                                                        // autoMinValue={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Text className="max-w-3xl mt-4">This curve represents the probability of experiencing a specific loss. The X-axis indicates the expected loss, while the Y-axis represents the probability of occurrence for that loss.</Text>
                                                            </Col>
                                                            <Col>
                                                                <div>
                                                                    <Text className="pt-4 text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">Loss Exceedance Curve</Text>
                                                                    <LineChart
                                                                        className="mt-4 h-80"
                                                                        style={{
                                                                            transform: "translateX(-18px)"
                                                                        }}
                                                                        data={
                                                                            [
                                                                                ...(views.risk[selectedTab].lossCurve && views.risk[selectedTab].lossCurve[0] && Array.isArray(views.risk[selectedTab].lossCurve[0])
                                                                                    ? views.risk[selectedTab].lossCurve[0]
                                                                                    : views.risk[selectedTab].lossCurve ? views.risk[selectedTab].lossCurve : []
                                                                                ).map((x) => ({
                                                                                    cost: valueFormatter(x.cost),
                                                                                    percentage: x.percentage
                                                                                }))
                                                                            ]
                                                                        }

                                                                        index="cost"
                                                                        categories={["percentage"]}
                                                                        showLegend={false}
                                                                        colors={["teal"]}
                                                                        curveType="monotone"
                                                                        /*
                                                                        rotateLabelX={{
                                                                          angle: 45,
                                                                        }}
                                                                        */
                                                                        intervalType="preserveStartEnd"
                                                                        // showYAxis={false}
                                                                        yAxisWidth={52}
                                                                        valueFormatter={(number) => `${number.toFixed(0).toString()}%`}
                                                                    // connectNulls={true}
                                                                    // autoMinValue={true}
                                                                    // enableLegendSlider={true}
                                                                    />
                                                                </div>
                                                                <Text className="max-w-3xl mt-4">This curve shows the probability that losses exceed certain values. The X-axis displays the expected loss, while the Y-axis displays the probabilities of exceeding these losses.</Text>
                                                            </Col>
                                                        </Grid>
                                                    </Card>

                                                    <Card>
                                                        <Title className='mb-4'>Calculation dependency tree</Title>
                                                        {
                                                            [
                                                                {
                                                                    label: "Calculated",
                                                                    color: "#0F6066"
                                                                },
                                                                {
                                                                    label: "Supplied",
                                                                    color: "#5C959F"
                                                                },
                                                                {
                                                                    label: "Not required",
                                                                    color: "#394444"
                                                                }
                                                            ].map(x => (
                                                                <div class="flex items-center gap-2">
                                                                    <div class="rounded-full h-5 w-5" style={{
                                                                        backgroundColor: x.color
                                                                    }}></div>
                                                                    <span style={{
                                                                        color: "#D1D1D1"
                                                                    }}>{x.label}</span>
                                                                </div>
                                                            ))
                                                        }
                                                        <div className="min-h-[560px] max-w-screen-xl mx-auto w-full aspect-[3/1] my-4">
                                                            {views.risk[selectedTab].fairTree ? <ParentSize>
                                                                {({ width, height }) => (
                                                                    <ChartTree
                                                                        background={darkMode ? "#192328" : "#FFFFFF"}
                                                                        textColor={darkMode ? "#FFFFFF" : "#111827"}
                                                                        height={height}
                                                                        margin={{ bottom: 48, left: 12, right: 12, top: 48 }}
                                                                        width={width}
                                                                        rawTree={views.risk[selectedTab].fairTree}
                                                                    />
                                                                )}
                                                            </ParentSize> : null}
                                                        </div>
                                                        {
                                                            /*
                                                        }
                                                        <a
                                                            className='text-xs'
                                                            href="https://www.fairinstitute.org/hubfs/FAIR-Flowchart.png"
                                                            target="_blank"
                                                        >
                                                            link to image
                                                        </a>
                                                        <Text className="max-w-lg">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Phasellus tempor lorem non est congue blandit. Praesent non
                                                            lorem sodales, suscipit est sed.
                                                        </Text>
                                                        {
                                                            */
                                                        }
                                                    </Card>
                                                    <Card>
                                                        <Title>Scenario for {views.risk[selectedTab].riskData.name}</Title>
                                                        <div id='1-detail' ref={detailRef} style={{
                                                            scrollMarginTop: 160
                                                        }}>
                                                            <Text className='text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis'>
                                                                <br />
                                                                {
                                                                    views.risk[selectedTab].riskData.name === "RANSOMWARE"
                                                                        ? <>
                                                                            <strong>Asset</strong>
                                                                            <br />
                                                                            In this scenario, we exclusively evaluate the risk of a ransomware attack, therefore we focus on data encryption only, disregarding data exfiltration.
                                                                            <br />
                                                                            <br />
                                                                            <strong>Threat</strong>
                                                                            <br />
                                                                            A ransomware is a type of malicious software that encrypts a victim's data, rendering it inaccessible. Attackers demand a ransom, usually in cryptocurrency, in exchange for providing the decryption key.
                                                                            <br />
                                                                            <br />
                                                                            <strong>Threat Actors</strong>
                                                                            <br />
                                                                            Most of the ransomware groups we analyzed operate as a RaaS (Ransomware as a Service) with the aim of damaging the reputation of a company, and extorting money.
                                                                            <br />
                                                                            Famous ransomware groups: BlackCat, Cl0p, and Lockbit
                                                                            <br />
                                                                            <br />
                                                                            <strong>Effects:</strong>
                                                                            <br />
                                                                            The effects considered for this scenario in the event of a ransomware attack are as follows:
                                                                            <ul className='flex flex-col gap-1 list-disc list-inside marker:text-teal-500'>
                                                                                <li>
                                                                                    Integrity: Information may become incomplete or inaccurate.
                                                                                </li>
                                                                                <li>
                                                                                    Availability: Services may be unavailable, resulting in loss.
                                                                                </li>
                                                                            </ul>
                                                                            <br />
                                                                            Statistical data shows ransomware attacks may last 1 day minimally, 8 days on average, and up to 73 days in extreme cases.
                                                                            Our analyses consider these durations when evaluating the impact of a ransomware attack on a company.
                                                                            <br />
                                                                            <br />
                                                                            <strong>Most Common TTPs (Tactics, Techniques, and Procedures):</strong>
                                                                            <br />
                                                                            <ul className='flex flex-col gap-1 list-disc list-inside marker:text-teal-500'>
                                                                                <li>
                                                                                    Phishing: Phishing campaigns remain one of the most common ways through which ransomware is spread. Attackers send deceptive emails that prompt users to click on malicious links or open harmful attachments.
                                                                                </li>
                                                                                <li>
                                                                                    Exploits and Vulnerabilities: Exploiting security vulnerabilities in software or operating systems remains a common tactic. This may involve using known or recently discovered exploits to gain access to the system.
                                                                                </li>
                                                                                <li>
                                                                                    Remote Desktop Protocol (RDP): Unauthorized access through RDP is another common TTP. Attackers seek to exploit weak RDP configurations to enter systems and deploy ransomware.
                                                                                </li>
                                                                            </ul>
                                                                            <br />
                                                                            However, it's important to note that these tactics are often used in combination. For example, a phishing campaign may be the initial step to gain access, followed by the use of exploits or RDP to propagate ransomware within the network.
                                                                        </>
                                                                        : views.risk[selectedTab].riskData.name === "DATABREACH"
                                                                            ? <>
                                                                                <strong>Asset</strong>
                                                                                <br />
                                                                                In this scenario we focus on analyzing the risk of pure data breach, therefore only data exfiltration. So the assets in danger are only the data, more precisely the Customer PII (Personally Identifiable Information).
                                                                                <br />
                                                                                <br />
                                                                                <strong>Threat</strong>
                                                                                <br />
                                                                                A data breach attack occurs when a hacker or a group of hackers gain unauthorized access to an organization's sensitive data. Typically, the attack starts by compromising access credentials through techniques like phishing, malware, or exploiting software vulnerabilities. Once access is obtained, the hacker exfiltrates the data. This data can then be sold, used for extortion, or exploited for further attacks.
                                                                                <br />
                                                                                <br />
                                                                                <strong>Threat Actors</strong>
                                                                                <br />
                                                                                Most of the cybercriminal groups we analyzed operate with the aim of damaging the reputation of a company, and extorting money.
                                                                                <br />
                                                                                Famous data breach groups: ShinyHunters, Lapsus$, and Magecart
                                                                                <br />
                                                                                <br />
                                                                                <strong>Effects:</strong>
                                                                                <br />
                                                                                The effects we consider for this scenario if we are attacked by ransomware are as follows:
                                                                                <ul className='flex flex-col gap-1 list-disc list-inside marker:text-teal-500'>
                                                                                    <li>
                                                                                        Confidentiality: Your personal information are published online
                                                                                    </li>
                                                                                </ul>
                                                                            </> : "No info available yet."}
                                                            </Text>
                                                        </div>
                                                    </Card>
                                                    <Card>
                                                        <Title>Methodology</Title>
                                                        <div id='2-methodology' ref={methodologyRef} style={{
                                                            scrollMarginTop: 160
                                                        }}>
                                                            <Text className='text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis'>
                                                                The FAIR (Factor Analysis of Information Risk) model is a methodology designed to assess and quantify information risk in a structured manner. The FAIR process relies on two main elements: "factors" and "analysis."
                                                                <br />
                                                                <br />
                                                                Factors: FAIR identifies and measures key factors contributing to information risk. These include Events (security incidents), Scenarios (how attackers might exploit the event), Assets (involved resources), Impacts (damages resulting from the event), and Controls (existing security measures).
                                                                <br />
                                                                <br />
                                                                Analysis: FAIR employs a quantitative approach to analyze and estimate risk. By assigning numerical values to each factor, the model allows for the calculation of a quantitative estimate of the overall risk. For instance, the probability of an event can be assessed as a percentage, and financial impacts can be measured in monetary values.
                                                                <br />
                                                                <br />
                                                                The FAIR model utilizes the PERT distribution to represent the uncertainty associated with various risk factors. These factors may include the likelihood of a harmful event, the financial impact of an incident, or other risk elements.
                                                                By using the PERT distribution, it is possible to more accurately quantify the variability and uncertainty associated with risk factors, allowing for a more precise assessment of overall risk.
                                                            </Text>
                                                        </div>
                                                    </Card>
                                                    {false &&
                                                        <Card>
                                                            <Title>Sources</Title>
                                                            <div id='2-sources'>
                                                                <Text className='text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis'>
                                                                    {
                                                                        // <strong>Ransom payment:</strong>
                                                                    }
                                                                    <br />
                                                                    <ul className='flex flex-col gap-1 list-disc list-inside marker:text-teal-500'>
                                                                        <li>
                                                                            doubleextortion.com
                                                                        </li>
                                                                        <li>
                                                                            Ransomware live
                                                                        </li>
                                                                        <li>
                                                                            Sophos reports
                                                                        </li>
                                                                        {
                                                                            /*
                                                                        }
                                                                    </ul>
                                                                    <br />
                                                                    <br />
                                                                    <strong>Voluntary improvements:</strong>
                                                                    <ul className='flex flex-col gap-1 list-disc list-inside marker:text-teal-500'>
                                                                    {
                                                                        */
                                                                        }
                                                                        <li>
                                                                            {
                                                                                // <a target="_blank" href="https://www.itmagination.com/blog/how-much-should-companies-spend-on-their-it-budget#:~:text=Industry%20Benchmarks%20for%20IT%20Budget%20Spending,-To%20provide%20some&text=For%20mid%2Dsized%20companies%20(between,is%20~2%25%20of%20revenue">
                                                                            }
                                                                            itmagination.com
                                                                            {
                                                                                // </a>
                                                                            }
                                                                        </li>
                                                                        <li>
                                                                            {
                                                                                // <a target="_blank" href="https://techvera.com/how-much-should-a-company-spend-on-it/#:~:text=The%204%2D6%25%20average%20is,company's%20individual%20needs%20and%20goals.">
                                                                            }
                                                                            techvera.com
                                                                            {
                                                                                //</a>
                                                                            }
                                                                        </li>
                                                                        <li>
                                                                            {
                                                                                // <a target="_blank" href="https://www.gbtech.net/how-much-should-your-company-spend-on-it/">
                                                                            }
                                                                            gbtech.net
                                                                            {
                                                                                // </a>
                                                                            }
                                                                        </li>
                                                                        <li>
                                                                            {
                                                                                // <a target="_blank" href="https://www.researchgate.net/publication/251237085/figure/fig9/AS:669504984068116@1536633835527/IT-spending-as-a-percentage-of-revenue-by-industry-Gartner-World-wide-IT-Benchmark.ppm">
                                                                            }
                                                                            researchgate.net
                                                                            {
                                                                                // </a>
                                                                            }
                                                                        </li>
                                                                        {
                                                                            /*
                                                                            </ul>
                                                                            <br />
                                                                            <br />
                                                                            <strong>Recovery cost:</strong>
                                                                            <ul className='flex flex-col gap-1 list-disc list-inside marker:text-teal-500'>
            
                                                                                <li>Sophos report</li>
                                                                            </ul>
                                                                            <br />
                                                                            <br />
                                                                            <strong>Business Interruption:</strong>
                                                                            <ul className='flex flex-col gap-1 list-disc list-inside marker:text-teal-500'>
                                                                                <li>Sophos report</li>
                                                                                {
                                                                                    */
                                                                        }
                                                                    </ul>
                                                                </Text>
                                                            </div>
                                                        </Card>
                                                    }
                                                </TabPanel>
                                            ))}
                                            {
                                                /*
                                            }
                                            <TabPanel className="mt-4 space-y-4">
                                                <Card>
                                                    <Flex className='justify-start gap-2'>
                                                        <Icon
                                                            icon={InfoSharp}
                                                            color="gray"
                                                            variant="simple"
                                                            tooltip="Not available"
                                                            size="sm"
                                                        />
                                                        <Text className='text-xs'>
                                                            not available yet
                                                        </Text>
                                                    </Flex>
                                                </Card>
                                            </TabPanel>
                                            {
                                                */
                                            }
                                        </TabPanels>
                                    </TabGroup>
                                </div>
                            )
                    ) : views && views.error
                        ? <a type="button" onClick={() => {
                            // <version>(v1))/account/(?P<account_req_uuid>(\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b))/rating/recalc/$",
                            //let groupSelectData = [];
                            // call table of asset + call table of checks
                            fetch(`${cysrConfig().api_base_url}account/${accountUUID}/rating/recalc/`, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                },
                            })
                                .then((response) => response.json())
                                .then((riskResponse) => {
                                    toast.success("Recalculation queued, it may take up to 10 minutes.")
                                    // toast.success(JSON.stringify(riskResponse))
                                })
                                .catch((err) => {
                                    toast.error(JSON.stringify(err))
                                });

                        }}>
                            <ImportantMessage
                                // (v1))/account/(?P<account_req_uuid>(\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b))/rating/recalc/
                                button={"Recalculate"}
                                className={`text-black dark:text-white text-left w-full border pl-11 pr-12 py-3 rounded border-cyan-500/50 bg-cyan-500/5`}
                                title={views.error}
                                description="Risk data will be available at the company's next check or by pressing the following button. Please note that the calculation may take up to 10 minutes."
                            />
                        </a>
                        : <Loader />}
                </div>

            </>
        </DashboardLayout >
    )
}

export default Risk;


function getDescription(x, y = "long") {
    switch (x.riskData.name.toLowerCase()) {
        case "ransomware":
            return y === "short"
                ? "Malicious software that encrypts a victim's data, making it inaccessible."
                : "A ransomware is a type of malicious software (malware) designed to encrypt files or lock access to a computer system, rendering it inaccessible to the user. Typically, once the ransomware has taken control, it demands a ransom payment, usually in cryptocurrency, in exchange for providing the decryption key or unlocking the system. Ransomware attacks can have devastating effects on individuals, businesses, and organizations, often causing data loss, financial damage, and operational disruptions.";
        case "ddos":
            return y === "short"
                ? "Cyber attack where a large number of devices or computers connected to the internet are coordinated to send a continuous stream of requests to a server or system, overwhelming it and making it inaccessible to legitimate users."
                : "A Distributed Denial of Service (DDoS) attack is a type of cyber attack where a large number of devices or computers connected to the internet are coordinated to send a continuous stream of requests to a server or system, overwhelming it and making it inaccessible to legitimate users. DDoS attacks can have harmful consequences, compromising the availability of online services and causing financial losses or reputational damage to the targeted organizations.";
        case "databreach":
            return y === "short"
                ? "Cybersecurity breach in which an intruder gains unauthorized access to sensitive or confidential data."
                : "A data breach attack is a type of cybersecurity breach in which an intruder gains unauthorized access to sensitive or confidential data, such as personal information, financial data, or business details. These attacks can occur through vulnerabilities in computer systems, network hacking, or by exploiting human errors like weak password security. Once compromised, data can be stolen, damaged, or made public, posing serious risks to privacy and security.";
        default:
            return "";
    }
}


export function getRatingColor(x) {
    /*
    BLU            <0.1    (meno di 1 in 10 anni)
AZZURRO        >=0.1    (>1 evento in 10 anni)
GIALLO        >=0.20    (>1 evento in 5 anni)
ARANCIO        >=0.3    (>1.2 eventi in 4 anni)
ROSSO        >=0.4    (1.2 eventi in 3 anni)
    */
    return `#${x.riskData.badge.lef.averageValue > .3999
        ? "E7212E"
        : x.riskData.badge.lef.averageValue > .2999
            ? "F05E25"
            : x.riskData.badge.lef.averageValue > .1999
                ? "F6921F"
                : x.riskData.badge.lef.averageValue > .0999
                    ? "57A6D9"
                    : x.riskData.badge.lef.averageValue < .1
                        ? "2466B0" : "CCCCCC"}`;
}
/* eslint-disable */
// prettier-ignore
