/* eslint-disable */
// prettier-ignore
import { Base64 } from 'js-base64';
import { useState, useEffect } from "react";
import ComplyLayout from "layouts/cysr/invite-comply/ComplyLayout";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// @mui material components
import Grid from "@mui/material/Grid";
import Loader from "layouts/cysr/components/Loader";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { toast } from "sonner"
// Ctrl s
import "layouts/cysr/comply/style.css";
import { CtrlTableAssets } from "layouts/cysr/comply/components/table-assets";
import { CtrlQuestionnairesTracker } from "layouts/cysr/comply/components/questionnaires-tracker";
import { CtrlQuestionnairesBarList } from "layouts/cysr/comply/components/questionnaires-bar-list";
import { CtrlTableQuestionnaires } from "layouts/cysr/comply/components/table-questionnaires";
import { CtrlWizardQuestionnaire } from "layouts/cysr/comply/components/wizard-questionnaire";
import { CategoryBar } from '@tremor/react';
import Card from "@mui/material/Card";
import cysrConfig from "layouts/cysr/config";
import { ComplyCredits } from '../comply/components/comply-credits';

// const MuscopeInviteAuth = "b6de35149db511ef95970242ac13000517664c1fe2484759a40e994cb6d40538";
let MuscopeInviteAuth = "";//"48eebdb69db611ef86060242ac130005f9539f9b9b694ec78c775cfd5991e1ab";
// extract id
if (window.location.hash !== undefined) {
    MuscopeInviteAuth = window.location.hash.replace("#", "");
}

if (false == (MuscopeInviteAuth && MuscopeInviteAuth.length > 0)) {
    MuscopeInviteAuth = sessionStorage.getItem("mu-in-auth");
}

const language = "en";

// Ctrl e
export default function InviteComply() {
    // const accountUUID = window.location.pathname.substring(
    //     window.location.pathname.indexOf("/company/") + "/company/".length,
    //     window.location.pathname.lastIndexOf("/")
    // );
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    /*
    // filter test s
    const params = new URLSearchParams(window.location.search);
    let activeTags = [];
    try {
        activeTags = params.get('t') && (decodeURI(params.get('t'))) && Base64.decode(decodeURI(params.get('t'))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))) && JSON.parse(Base64.decode(decodeURI(params.get('t')))).length > 0 ? JSON.parse(Base64.decode(decodeURI(params.get('t')))) : [];
    } catch (error) {
        console.error("Error in setting active tags:")
        console.error(error)
    }
    // filter test e
    */

    // Our State.
    const [showAddQuestionnaire, setShowAddQuestionnaire] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [views, setViews] = useState([]);
    const [frameworks, setFrameworks] = useState([]);
    const [questionnaireName, setQuestionnaireName] = useState("");
    const [questionnaires, setQuestionnaires] = useState([]);
    const [assets, setAssets] = useState([]);
    // /{v}/company/{id}/assets
    // const getAssetsUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/assets`;
    // /{v}/company/{id}/questionnaires
    // /{version}/invited-user/questionnaires    
    // const getQuestionnairesUrl = `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/questionnaires`;
    const getQuestionnairesUrl = `${cysrConfig().ctrl_api_base_url}invited-user/questionnaires`;
    // /{v}/frameworks
    const getFrameworksUrl = `${cysrConfig().ctrl_api_base_url}frameworks`;
    const assetsResponse = [];
    const frameworksResponse = [];
    useEffect(() => {
        if (MuscopeInviteAuth && MuscopeInviteAuth.length > 0) {
            // overwrite mu-in-auth
            sessionStorage.setItem("mu-in-auth", MuscopeInviteAuth)
        }
        const pageTitle = "Comply";
        document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
        if (MuscopeInviteAuth) {

            // getQuestionnairesUrl
            fetch(getQuestionnairesUrl, {
                headers: {
                    // Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                    "Muscope-Invite-Auth": MuscopeInviteAuth,
                    'Accept-Language': language,
                },
            })
                .then((response) => response.json())
                .then(async (questionnairesRes) => {

                    if (questionnairesRes.Error) {
                        throw (questionnairesRes?.Error)
                    }
                    const questionnairesResponse = Object.values(questionnairesRes).flat();
                    setQuestionnaires(questionnairesResponse)
                    // // getAssetsUrl
                    // fetch(getAssetsUrl, {
                    //     headers: {
                    //         // Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                    //         "Muscope-Invite-Auth": MuscopeInviteAuth
                    //     },
                    // })
                    //     .then((response) => response.json())
                    //     .then(async (assetsResponse) => {

                    //         if (assetsResponse.Error) {
                    //             throw (assetsResponse.Error)
                    //         }

                    setAssets(assetsResponse)
                    setViews({
                        assets: assetsResponse,
                        questionnaires: questionnairesResponse,
                        frameworks: frameworksResponse
                    });
                    // })
                    // .catch((err) => {
                    //     console.error(0, err)
                    //     toast.error(err)
                    //     setViews({
                    //         questionnaires: questionnairesResponse,
                    //         frameworks: frameworksResponse
                    //     });
                    // });
                })
                .catch((err) => {
                    console.error(1, err)
                    toast.error(err)
                    setViews({
                        questionnaires: null,
                        frameworks: frameworksResponse
                    });
                });


        } else {
            setViews({
                questionnaires: null,
                frameworks: null
            });
        }
    }, []);
    return (
        <ComplyLayout>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {MuscopeInviteAuth === "" ?
                            "Write down your invitation code or check the email link"
                            :

                            questionnaires ?
                                <Card>
                                    <div className={darkMode ? "dark" : "light"}>
                                        <div className='px-4 py-4'>
                                            <div className='flex gap-4 justify-between'>
                                                <h3 className="mt-2 text-black dark:text-white text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">Questionnaires</h3>
                                                {false ? <div className='ml-auto'>
                                                    <MDButton onClick={() => setShowAddQuestionnaire(!showAddQuestionnaire)} style={cysrConfig().button_style} size="medium">
                                                        {showAddQuestionnaire ? (
                                                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().reduce.icon.svg }} />
                                                        ) : (
                                                            <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().expand.icon.svg }} />
                                                        )}
                                                        Add a questionnaire
                                                    </MDButton>
                                                </div> : null
                                                }
                                            </div>

                                            {
                                                questionnaires && questionnaires.length > 0 ? <>
                                                    <div className='flex flex-col lg:flex-row gap-4'>
                                                        <div className='w-full'>
                                                            <div className="flex items-center justify-between border-b border-tremor-border py-6 dark:border-dark-tremor-border">
                                                                <p className="text-base font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                                                    Progress overview
                                                                </p>
                                                                {
                                                                    /*
                                                                <p className="text-sm text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                                                                    Controls
                                                                </p>
                                                                */
                                                                }
                                                            </div>
                                                            <div className='my-6'>
                                                                <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content flex items-center justify-between">
                                                                    <span>Questionnaires answered</span>
                                                                    <span>{((questionnaires.filter(q => q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total)).length / questionnaires.length) * 100).toFixed(0)}% &bull; <strong className='font-medium text-black dark:text-white'>{questionnaires.filter(q => q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total)).length} of {questionnaires.length}</strong></span>
                                                                </p>
                                                                <CategoryBar
                                                                    showLabels={false}
                                                                    values={[
                                                                        (questionnaires.filter(q => q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total)).length / questionnaires.length) * 100,
                                                                        (questionnaires.filter(q => !(q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total))).length / questionnaires.length) * 100
                                                                    ]}
                                                                    tooltip={`${((questionnaires.filter(q => q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total)).length / questionnaires.length) * 100).toFixed(0)}% answered`}
                                                                    colors={['cyan', 'gray']}
                                                                    markerValue={(questionnaires.filter(q => q.questionnaire_completion && q.questionnaire_completion.answered > 0 && (q.questionnaire_completion.answered === q.questionnaire_completion.total)).length / questionnaires.length) * 100}
                                                                    className="mt-3"
                                                                />

                                                            </div>
                                                            <CtrlQuestionnairesTracker questionnaires={questionnaires} />
                                                        </div>
                                                        {false &&
                                                            <CtrlQuestionnairesBarList questionnaires={questionnaires} />
                                                        }
                                                    </div>
                                                    <CtrlTableQuestionnaires isInvited={true} questionnaires={questionnaires} setQuestionnaires={setQuestionnaires} />
                                                </>
                                                    : <p className='mb-2 text-left text-sm text-zinc-500 dark:text-zinc-300'>No questionnaires found.</p>
                                            }
                                        </div>
                                    </div>
                                </Card>
                                :
                                <Loader />
                        }
                        {
                            /*
                        }
                        {views && views.assets ?
                            <Card className='mt-4'>
                                <div className={darkMode ? "dark" : "light"}>
                                    <div className='px-4 py-4'>
                                        <h3 className="mt-2 text-black dark:text-white text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">List of assets</h3>
    
                                        {
                                            assets && assets.length > 0 ? <>
                                                <CtrlTableAssets account_uuid={accountUUID} assets={assets} />
                                            </>
                                                : <p className='mb-2 text-left text-sm text-zinc-500 dark:text-zinc-300'>No assets found.</p>
                                        }
                                    </div>
                                </div>
                            </Card>
                            :
                            <Loader />
                        }
                        {
                            */
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <ComplyCredits />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </ComplyLayout>
    );
}
